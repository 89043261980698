import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { GET_STORE_ADDRESSES_DATA } from 'api/orders';
import Exports from 'containers/ExportsOrders';
import GetStartedProvider from 'containers/GetStarted/Provider';
import { useGetStartedContext } from 'contexts/GetStarted';
import { isAfter, isBefore } from 'date-fns';
import { formatPrice, isPositive } from 'helpers';
import clsx from 'helpers/clsx';
import { getCountry, getStoreAddressId } from 'helpers/jwt';
import { useClickAwayListener } from 'hooks/useClickAwayListener';
import { useOrders } from 'hooks/useOrders';
import { useUser } from 'hooks/useUser';
import { CountryCodeEnum, Entries } from 'interfaces';
import { IPromoIdResponse, PromoCalculationType } from 'interfaces/order';
import { ReactComponent as AfterPeriodIcon } from 'static/images/afterPeriodChanges.svg';
import { ReactComponent as Chart } from 'static/images/chart.svg';
import { ReactComponent as DeliveryFee } from 'static/images/delivery-fee.svg';
import { ReactComponent as InfoIcon } from 'static/images/info.svg';
import { ReactComponent as Arr } from 'static/images/keyboard_arrow_down.svg';
import { ReactComponent as PartnerIcon } from 'static/images/partner.svg';
import { ReactComponent as PromoDeliveryIcon } from 'static/images/promo-delivery.svg';
import { ReactComponent as PromoProductsIcon } from 'static/images/promo-products.svg';
import { ReactComponent as TotalIcon } from 'static/images/total.svg';
import { ReactComponent as WarningIcon } from 'static/images/warningIcon.svg';
import { TEST_IDS } from 'tests/config';

import OnloadDotted from 'components/OnloadDotted';
import { DarkTooltip } from 'components/StyledDarkMenuTooltip';
import { LightTooltip } from 'components/StyledLightTooltip';

import classes from './Orders.module.scss';

interface IStateOpened {
	baseAgentTotal: boolean;
	agentTotalWithVat: boolean;
	partnerTotal: boolean;
	promoProducts: boolean;
	promoDelivery: boolean;
	deliveryFee: boolean;
}

const OrdersTotals: React.FC = () => {
	const { open } = useGetStartedContext();
	const { hasMarketplace, isServiceUser } = useUser();
	const { totals, loadingTotals, filter } = useOrders();
	const { t } = useTranslation(['orders', 'exports', 'common']);
	const refTotals = useRef(null);
	const country = getCountry();
	const [isPartnerWithPromo, setIsPartnerWithPromo] = useState(false);
	const [opened, setOpened] = useState<IStateOpened>({
		baseAgentTotal: false,
		agentTotalWithVat: false,
		partnerTotal: false,
		promoProducts: false,
		promoDelivery: false,
		deliveryFee: false,
	});
	const { data: storesData } = useQuery(GET_STORE_ADDRESSES_DATA, {
		variables: {
			storeAddressIds: getStoreAddressId(),
		},
	});

	const clearOpened = () => {
		setOpened((state) => ({
			...Object.fromEntries(Object.entries(state).map(([key]: Entries<IStateOpened>) => [key, false])),
		}));
	};

	const handleChangeOpened = (field: keyof IStateOpened) => () => {
		setOpened((state) => ({
			...Object.fromEntries(
				Object.entries(state)
					.filter(([key]) => key !== field)
					.map(([key]: Entries<IStateOpened>) => [key, false]),
			),
			[field]: !state[field],
		}));
	};

	const getStartedOpened = () => !open && clearOpened();

	useClickAwayListener(refTotals, getStartedOpened, open);

	const promoTypeWithCondition = () => {
		return !!storesData?.storeAddressData.find((it: IPromoIdResponse) => it.promoCalculationType === PromoCalculationType.FULL);
	};

	useEffect(() => {
		if (open) {
			handleChangeOpened('baseAgentTotal')();
		} else {
			clearOpened();
		}
	}, [open]);

	useEffect(() => {
		if (!storesData) return;
		setIsPartnerWithPromo(promoTypeWithCondition() || isServiceUser);
	}, [storesData, hasMarketplace]);

	const isUA = country === CountryCodeEnum.UA;

	const showPaymentCalculationWarning =
		filter.startDate &&
		isBefore(filter.startDate, new Date('2024-02-01')) &&
		filter.endDate &&
		isAfter(filter.endDate, new Date('2024-01-31')) &&
		isUA;

	return (
		<div className={classes.totals}>
			<div className={clsx(classes.finances)} ref={refTotals}>
				<div
					className={clsx(classes.finances_item, classes.finances_item_total, {
						[classes.finances_item__opened]: opened.baseAgentTotal,
						[classes.finances_item_afterPeriod]: isUA,
					})}
				>
					<GetStartedProvider sort={1} id="baseAgentTotal" position="rightTop" borderRadius={20} beforeNext={handleChangeOpened('agentTotalWithVat')}>
						<div>
							<div className={clsx(classes.finances_item_top, classes.alignCenter, { [classes.finances_item_top_afterPeriod]: isUA })}>
								<TotalIcon />
								<div>
									<div className={classes.finances_item_text}>{t('totals.baseAgentTotal')}</div>
									{loadingTotals ? (
										<OnloadDotted className={classes.loadingTotals}>{t('common:loading')}</OnloadDotted>
									) : (
										<div className={classes.finances_item_title}>
											{formatPrice(totals && totals?.main.agentFeeCalculationBase, 2, ',')}
											{isUA && (
												<DarkTooltip title={t('tooltip.totalsAfterPeriod')} placement="right" color="#fff" arrow>
													<div className={clsx(classes.totalsAfterPeriod, classes.totalsAfterPeriodHover)}>
														<AfterPeriodIcon />
														{formatPrice(totals?.period?.agentFeeCalculationBase, 2, ',', '---', true)}
													</div>
												</DarkTooltip>
											)}
											<button
												className={classes.finances_item_toggle}
												onClick={handleChangeOpened('baseAgentTotal')}
												data-testid={TEST_IDS.ORDERS_TOTALS_BUTTON_BASE_AGENT}
											>
												{t('common:detail')}
												<Arr />
											</button>
										</div>
									)}
								</div>
							</div>
							<div className={clsx(classes.finances_item_bottom, classes.spaceBetween)}>
								<div className={classes.finances_item_text}>
									{t('totals.orderTotal')}
									{loadingTotals ? (
										<OnloadDotted className={classes.loadingTotals} />
									) : (
										<div className={classes.finances_item_wrap}>
											<div className={clsx(classes.finances_item_subtitle, classes.success)}>{formatPrice(totals?.main.orderTotal, 2, ',')}</div>
											{isUA && (
												<DarkTooltip title={t('tooltips.orderTotal')} placement="right" color="#fff" arrow>
													<div
														className={clsx(classes.success, classes.totalsAfterPeriodHover, {
															[classes.error]: !isPositive(totals?.period.orderTotal),
														})}
													>
														{formatPrice(totals?.period?.orderTotal, 2, ',', '---', true)}
													</div>
												</DarkTooltip>
											)}
										</div>
									)}
								</div>
								<div className={classes.finances_item_text}>
									{t('totals.discountTotal')}
									{loadingTotals ? (
										<OnloadDotted className={classes.loadingTotals} />
									) : (
										<div className={classes.finances_item_wrap}>
											<div className={clsx(classes.finances_item_subtitle, classes.error)}>{formatPrice(totals?.main.productDiscount, 2, ',')}</div>
											{isUA && (
												<DarkTooltip title={t('tooltips.productDiscount')} placement="right" color="#fff" arrow>
													<div
														className={clsx(classes.success, classes.totalsAfterPeriodHover, {
															[classes.error]: !isPositive(totals?.period?.productDiscount),
														})}
													>
														{formatPrice(totals?.period?.productDiscount, 2, ',', '---', true)}
													</div>
												</DarkTooltip>
											)}
										</div>
									)}
								</div>
								<div className={classes.finances_item_text}>
									{t('totals.chargedToPartnerTotal')}
									{loadingTotals ? (
										<OnloadDotted className={classes.loadingTotals} />
									) : (
										<div className={classes.finances_item_wrap}>
											<div className={clsx(classes.finances_item_subtitle, classes.error)}>
												{formatPrice(totals?.main.chargedToPartnerTotal, 2, ',')}
											</div>
											{isUA && (
												<DarkTooltip title={t('tooltips.chargedToPartnerTotal')} placement="right" color="#fff" arrow>
													<div
														className={clsx(classes.success, classes.totalsAfterPeriodHover, {
															[classes.error]: !isPositive(totals?.period.chargedToPartnerTotal),
														})}
													>
														{formatPrice(totals?.period.chargedToPartnerTotal, 2, ',', '---', true)}
													</div>
												</DarkTooltip>
											)}
										</div>
									)}
								</div>
							</div>
						</div>
					</GetStartedProvider>
				</div>
				<div
					className={clsx(classes.finances_item, classes.finances_item_compensation, {
						[classes.finances_item__opened]: opened.agentTotalWithVat,
						[classes.finances_item_afterPeriod]: isUA,
					})}
				>
					<GetStartedProvider
						sort={2}
						id="agentTotalWithVat"
						position="bottom"
						borderRadius={20}
						beforeNext={handleChangeOpened('partnerTotal')}
						beforePrev={handleChangeOpened('baseAgentTotal')}
					>
						<div>
							<div className={clsx(classes.finances_item_top, classes.alignCenter, { [classes.finances_item_top_afterPeriod]: isUA })}>
								<Chart />
								<div>
									<div className={classes.finances_item_text}>{t('totals.agentTotalWithVat')}</div>
									{loadingTotals ? (
										<OnloadDotted className={classes.loadingTotals}>{t('common:loading')}</OnloadDotted>
									) : (
										<div className={classes.finances_item_title}>
											{formatPrice(totals?.main.commissionTotalWithVat, 2, ',')}
											{showPaymentCalculationWarning && (
												<LightTooltip
													arrow
													backgroundColor="#FFFBED"
													color="#493F58"
													placement="bottom"
													className={classes.warningTooltip}
													title={
														<Trans
															t={t}
															i18nKey="tooltips.warning"
															components={{ bold: <span className={classes.bold} />, title: <span className={classes.title} /> }}
														/>
													}
												>
													<WarningIcon className={classes.finances_item_icon} />
												</LightTooltip>
											)}
											{isUA && (
												<DarkTooltip title={t('tooltip.totalsAfterPeriod')} placement="right" color="#fff" arrow>
													<div className={clsx(classes.totalsAfterPeriod, classes.totalsAfterPeriodHover)}>
														<AfterPeriodIcon />
														{formatPrice(totals?.period?.commissionTotalWithVat, 2, ',', '---', true)}
													</div>
												</DarkTooltip>
											)}
											<button
												className={classes.finances_item_toggle}
												onClick={handleChangeOpened('agentTotalWithVat')}
												data-testid={TEST_IDS.ORDERS_TOTALS_BUTTON_AGENT_TOTAL}
											>
												{t('common:detail')}
												<Arr />
											</button>
										</div>
									)}
								</div>
							</div>
							<div className={clsx(classes.finances_item_bottom, classes.spaceBetween)}>
								<div className={classes.finances_item_text}>
									{t('totals.agentTotalWithoutVat')}
									{loadingTotals ? (
										<OnloadDotted className={classes.loadingTotals} />
									) : (
										<div className={classes.finances_item_wrap}>
											<div className={clsx(classes.finances_item_subtitle, classes.success)}>{formatPrice(totals?.main.commissionTotal, 2, ',')}</div>
											{isUA && (
												<DarkTooltip title={t('tooltips.commissionTotal')} placement="right" color="#fff" arrow>
													<div
														className={clsx(classes.success, classes.totalsAfterPeriodHover, {
															[classes.error]: !isPositive(totals?.period?.commissionTotal),
														})}
													>
														{formatPrice(totals?.period?.commissionTotal, 2, ',', '---', true)}
													</div>
												</DarkTooltip>
											)}
										</div>
									)}
								</div>
								<div className={clsx(classes.finances_item_text, classes.borderVat)}>
									{t('totals.additionalAgentTotalWithoutVat')}
									<div className={classes.finances_item_wrap}>
										<div className={clsx(classes.finances_item_subtitle, classes.success)}>
											{formatPrice(totals?.main?.additionalCommissionWithoutVat, 2, ',')}
										</div>
										{isUA && (
											<DarkTooltip title={t('tooltips.additionalCommissionWithoutVat')} placement="right" color="#fff" arrow>
												<div
													className={clsx(classes.success, classes.totalsAfterPeriodHover, {
														[classes.error]: !isPositive(totals?.period?.additionalCommissionWithoutVat),
													})}
												>
													{formatPrice(totals?.period?.additionalCommissionWithoutVat, 2, ',', '---', true)}
												</div>
											</DarkTooltip>
										)}
									</div>
								</div>
								<div className={classes.finances_item_text}>
									{t('totals.vat')}
									{loadingTotals ? (
										<OnloadDotted className={classes.loadingTotals} />
									) : (
										<div className={classes.finances_item_wrap}>
											<div className={clsx(classes.finances_item_subtitle, classes.dark)}>{formatPrice(totals?.main.commissionTotalVat, 2, ',')}</div>
											{isUA && (
												<DarkTooltip title={t('tooltips.commissionTotalVat')} placement="right" color="#fff" arrow>
													<div className={clsx(classes.dark, classes.totalsAfterPeriodHover)}>
														{formatPrice(totals?.period?.commissionTotalVat, 2, ',', '---', true)}
													</div>
												</DarkTooltip>
											)}
										</div>
									)}
								</div>
							</div>
						</div>
					</GetStartedProvider>
				</div>
				<div
					className={clsx(classes.finances_item, classes.finances_item_payment, {
						[classes.finances_item__opened]: opened.partnerTotal,
						[classes.finances_item_afterPeriod]: isUA,
					})}
				>
					<GetStartedProvider sort={3} id="partnerTotal" position="leftTop" borderRadius={20} beforeNext={handleChangeOpened('promoProducts')}>
						<div>
							<div className={clsx(classes.finances_item_top, classes.alignCenter, { [classes.finances_item_top_afterPeriod]: isUA })}>
								<PartnerIcon />
								<div>
									<div className={classes.finances_item_text}>{t('totals.partnerTotal')}</div>
									{loadingTotals ? (
										<OnloadDotted className={classes.loadingTotals}>{t('common:loading')}</OnloadDotted>
									) : (
										<div className={classes.finances_item_title}>
											{formatPrice(totals?.main.partnerPaymentWithoutVat, 2, ',')}
											{isUA && (
												<DarkTooltip title={t('tooltip.totalsAfterPeriod')} placement="right" color="#fff" arrow>
													<div className={clsx(classes.totalsAfterPeriod, classes.totalsAfterPeriodHover)}>
														<AfterPeriodIcon />
														{formatPrice(totals?.period.partnerPaymentWithoutVat, 2, ',', '---', true)}
													</div>
												</DarkTooltip>
											)}
											<button
												className={classes.finances_item_toggle}
												onClick={handleChangeOpened('partnerTotal')}
												data-testid={TEST_IDS.ORDERS_TOTALS_BUTTON_PARTNER_TOTAL}
											>
												{t('common:detail')}
												<Arr />
											</button>
										</div>
									)}
								</div>
							</div>
							<div className={clsx(classes.finances_item_bottom, classes.spaceBetween)}>
								{filter.endDate && isAfter(filter.endDate, new Date('2024-01-31')) && isUA && (
									<>
										<div className={classes.finances_item_text}>
											{t('totals.partnerPaymentWithVat')}
											{loadingTotals ? (
												<OnloadDotted className={classes.loadingTotals} />
											) : (
												<div className={classes.finances_item_wrap}>
													<div className={clsx(classes.finances_item_subtitle, classes.success)}>
														{formatPrice(totals?.main.partnerPaymentWithVat, 2, ',')}
													</div>
													{isUA && (
														<DarkTooltip title={t('tooltips.partnerPaymentWithVat')} placement="right" color="#fff" arrow>
															<div
																className={clsx(classes.success, classes.totalsAfterPeriodHover, {
																	[classes.error]: !isPositive(totals?.period.partnerPaymentWithVat),
																})}
															>
																{formatPrice(totals?.period.partnerPaymentWithVat, 2, ',', '---', true)}
															</div>
														</DarkTooltip>
													)}
												</div>
											)}
										</div>
										<div className={classes.finances_item_text}>
											<div className={classes.finances_item_text_info}>
												{t('totals.partnerPaymentVat')}
												{showPaymentCalculationWarning && (
													<LightTooltip arrow placement="bottom" title={t('tooltips.partnerPaymentVat')}>
														<InfoIcon />
													</LightTooltip>
												)}
											</div>
											{loadingTotals ? (
												<OnloadDotted className={classes.loadingTotals} />
											) : (
												<div className={classes.finances_item_wrap}>
													<div className={clsx(classes.finances_item_subtitle, classes.error)}>
														{formatPrice(totals?.main.partnerPaymentVat, 2, ',')}
													</div>
													{isUA && (
														<DarkTooltip title={t('tooltips.partnerPaymentVatAfterPeriod')} placement="right" color="#fff" arrow>
															<div
																className={clsx(classes.success, classes.totalsAfterPeriodHover, {
																	[classes.error]: !isPositive(totals?.period.partnerPaymentVat),
																})}
															>
																{formatPrice(totals?.period.partnerPaymentVat, 2, ',', '---', true)}
															</div>
														</DarkTooltip>
													)}
												</div>
											)}
										</div>
									</>
								)}
								<div className={classes.finances_item_text}>
									{t('totals.paidCashTotal')}
									{loadingTotals ? (
										<OnloadDotted className={classes.loadingTotals} />
									) : (
										<div className={classes.finances_item_wrap}>
											<div className={clsx(classes.finances_item_subtitle, classes.success, { [classes.error]: isUA })}>
												{formatPrice(totals?.main.paidCashTotal, 2, ',')}
											</div>
											{isUA && (
												<DarkTooltip title={t('tooltips.paidCashTotal')} placement="right" color="#fff" arrow>
													<div className={clsx(classes.success, classes.totalsAfterPeriodHover, { [classes.error]: isUA })}>
														{formatPrice(totals?.period.paidCashTotal, 2, ',', '---', true)}
													</div>
												</DarkTooltip>
											)}
										</div>
									)}
								</div>
							</div>
						</div>
					</GetStartedProvider>
				</div>
				{isPartnerWithPromo && (
					<div
						className={clsx(classes.finances_item, classes.finances_item_promo_products, {
							[classes.finances_item__opened]: opened.promoProducts,
							[classes.finances_item_afterPeriod]: isUA,
						})}
					>
						<GetStartedProvider
							canPush={isPartnerWithPromo}
							sort={4}
							id="promoProducts"
							position="rightTop"
							borderRadius={20}
							beforeNext={handleChangeOpened('promoDelivery')}
						>
							<div>
								<div className={clsx(classes.finances_item_top, classes.alignCenter, { [classes.finances_item_top_afterPeriod]: isUA })}>
									<PromoProductsIcon />
									<div>
										<div className={classes.finances_item_text}>{t('totals.promoProducts')}</div>
										{loadingTotals ? (
											<OnloadDotted className={classes.loadingTotals}>{t('common:loading')}</OnloadDotted>
										) : (
											<div className={classes.finances_item_title}>
												{formatPrice(totals?.main.productDiscountAssumedByPartnerTotalWithVat, 2, ',')}
												{isUA && (
													<DarkTooltip title={t('tooltip.totalsAfterPeriod')} placement="right" color="#fff" arrow>
														<div className={clsx(classes.totalsAfterPeriod, classes.totalsAfterPeriodHover)}>
															<AfterPeriodIcon />
															{formatPrice(totals?.period.productDiscountAssumedByPartnerTotalWithVat, 2, ',', '---', true)}
														</div>
													</DarkTooltip>
												)}
												<button
													className={classes.finances_item_toggle}
													onClick={handleChangeOpened('promoProducts')}
													data-testid={TEST_IDS.ORDERS_TOTALS_BUTTON_PROMO_PRODUCTS}
												>
													{t('common:detail')}
													<Arr />
												</button>
											</div>
										)}
									</div>
								</div>
								<div className={clsx(classes.finances_item_bottom, classes.spaceBetween)}>
									<div className={classes.finances_item_text}>
										{t('totals.totalSumWitoutVat')}
										{loadingTotals ? (
											<OnloadDotted className={classes.loadingTotals} />
										) : (
											<div className={classes.finances_item_wrap}>
												<div className={clsx(classes.finances_item_subtitle, classes.success)}>
													{formatPrice(totals?.main.productDiscountAssumedByPartnerTotal, 2, ',')}
												</div>
												{isUA && (
													<DarkTooltip title={t('tooltips.productDiscountAssumedByPartnerTotal')} placement="right" color="#fff" arrow>
														<div
															className={clsx(classes.success, classes.totalsAfterPeriodHover, {
																[classes.error]: !isPositive(totals?.period.productDiscountAssumedByPartnerTotal),
															})}
														>
															{formatPrice(totals?.period.productDiscountAssumedByPartnerTotal, 2, ',', '---', true)}
														</div>
													</DarkTooltip>
												)}
											</div>
										)}
									</div>
									<div className={classes.finances_item_text}>
										{t('totals.vat')}
										{loadingTotals ? (
											<OnloadDotted className={classes.loadingTotals} />
										) : (
											<div className={classes.finances_item_wrap}>
												<div className={clsx(classes.finances_item_subtitle, classes.dark)}>
													{formatPrice(totals?.main.productDiscountAssumedByPartnerTotalVat, 2, ',')}
												</div>
												{isUA && (
													<DarkTooltip title={t('tooltips.productDiscountAssumedByPartnerTotalVat')} placement="right" color="#fff" arrow>
														<div className={clsx(classes.dark, classes.totalsAfterPeriodHover)}>
															{formatPrice(totals?.period.productDiscountAssumedByPartnerTotalVat, 2, ',', '---', true)}
														</div>
													</DarkTooltip>
												)}
											</div>
										)}
									</div>
								</div>
							</div>
						</GetStartedProvider>
					</div>
				)}
				{isPartnerWithPromo && (
					<div
						className={clsx(classes.finances_item, classes.finances_item_promo_delivery, {
							[classes.finances_item__opened]: opened.promoDelivery,
							[classes.finances_item_afterPeriod]: isUA,
						})}
					>
						<GetStartedProvider
							canPush={isPartnerWithPromo}
							sort={5}
							id="promoDelivery"
							position="bottom"
							borderRadius={20}
							beforeNext={handleChangeOpened('deliveryFee')}
						>
							<div>
								<div className={clsx(classes.finances_item_top, classes.alignCenter, { [classes.finances_item_top_afterPeriod]: isUA })}>
									<PromoDeliveryIcon />
									<div>
										<div className={classes.finances_item_text}>{t('totals.promoDelivery')}</div>
										{loadingTotals ? (
											<OnloadDotted className={classes.loadingTotals}>{t('common:loading')}</OnloadDotted>
										) : (
											<div className={classes.finances_item_title}>
												{formatPrice(totals?.main.deliveryDiscountAssumedByPartnerTotalWithVat, 2, ',')}
												{isUA && (
													<DarkTooltip title={t('tooltip.totalsAfterPeriod')} placement="right" color="#fff" arrow>
														<div className={clsx(classes.totalsAfterPeriod, classes.totalsAfterPeriodHover)}>
															<AfterPeriodIcon />
															{formatPrice(totals?.period.deliveryDiscountAssumedByPartnerTotalWithVat, 2, ',', '---', true)}
														</div>
													</DarkTooltip>
												)}
												<button
													className={classes.finances_item_toggle}
													onClick={handleChangeOpened('promoDelivery')}
													data-testid={TEST_IDS.ORDERS_TOTALS_BUTTON_PROMO_DELIVERY}
												>
													{t('common:detail')}
													<Arr />
												</button>
											</div>
										)}
									</div>
								</div>
								<div className={clsx(classes.finances_item_bottom, classes.spaceBetween)}>
									<div className={classes.finances_item_text}>
										{t('totals.totalSumWitoutVat')}
										{loadingTotals ? (
											<OnloadDotted className={classes.loadingTotals} />
										) : (
											<div className={classes.finances_item_wrap}>
												<div className={clsx(classes.finances_item_subtitle, classes.success)}>
													{formatPrice(totals?.main.deliveryDiscountAssumedByPartnerTotal, 2, ',')}
												</div>
												{isUA && (
													<DarkTooltip title={t('tooltips.deliveryDiscountAssumedByPartnerTotal')} placement="right" color="#fff" arrow>
														<div
															className={clsx(classes.success, classes.totalsAfterPeriodHover, {
																[classes.error]: !isPositive(totals?.period.deliveryDiscountAssumedByPartnerTotal),
															})}
														>
															{formatPrice(totals?.period.deliveryDiscountAssumedByPartnerTotal, 2, ',', '---', true)}
														</div>
													</DarkTooltip>
												)}
											</div>
										)}
									</div>
									<div className={classes.finances_item_text}>
										{t('totals.vat')}
										{loadingTotals ? (
											<OnloadDotted className={classes.loadingTotals} />
										) : (
											<div className={classes.finances_item_wrap}>
												<div className={clsx(classes.finances_item_subtitle, classes.dark)}>
													{formatPrice(totals?.main.deliveryDiscountAssumedByPartnerTotalVat, 2, ',')}
												</div>
												{isUA && (
													<DarkTooltip title={t('tooltips.deliveryDiscountAssumedByPartnerTotalVat')} placement="right" color="#fff" arrow>
														<div className={clsx(classes.dark, classes.totalsAfterPeriodHover)}>
															{formatPrice(totals?.period.deliveryDiscountAssumedByPartnerTotalVat, 2, ',', '---', true)}
														</div>
													</DarkTooltip>
												)}
											</div>
										)}
									</div>
								</div>
							</div>
						</GetStartedProvider>
					</div>
				)}
				{hasMarketplace && (
					<div
						className={clsx(classes.finances_item, classes.finances_item_deliveryFee, {
							[classes.finances_item__opened]: opened.deliveryFee,
							[classes.finances_item_afterPeriod]: isUA,
						})}
					>
						<GetStartedProvider
							canPush={hasMarketplace}
							sort={6}
							id="deliveryFee"
							position="leftTop"
							borderRadius={20}
							beforeNext={(currentStep) => currentStep?.id === 'deliveryFee' && handleChangeOpened('deliveryFee')()}
						>
							<div>
								<div className={clsx(classes.finances_item_top, classes.alignCenter, { [classes.finances_item_top_afterPeriod]: isUA })}>
									<DeliveryFee />
									<div>
										<div className={classes.finances_item_text}>{t('totals.partnerPaymentTotal')}</div>
										{loadingTotals ? (
											<OnloadDotted className={classes.loadingTotals}>{t('common:loading')}</OnloadDotted>
										) : (
											<div className={classes.finances_item_title}>
												{formatPrice(totals?.main.partnerPaymentTotal, 2, ',')}
												{isUA && (
													<DarkTooltip title={t('tooltip.totalsAfterPeriod')} placement="right" color="#fff" arrow>
														<div className={clsx(classes.totalsAfterPeriod, classes.totalsAfterPeriodHover)}>
															<AfterPeriodIcon />
															{formatPrice(totals?.period.partnerPaymentTotal, 2, ',', '---', true)}
														</div>
													</DarkTooltip>
												)}
												<button
													className={classes.finances_item_toggle}
													onClick={handleChangeOpened('deliveryFee')}
													data-testid={TEST_IDS.ORDERS_TOTALS_BUTTON_DELIVERY_FEE}
												>
													{t('common:detail')}
													<Arr />
												</button>
											</div>
										)}
									</div>
								</div>
								<div className={clsx(classes.finances_item_bottom, classes.spaceBetween, classes.newSpaceBetween)}>
									<div className={classes.finances_item_text}>
										{t('totals.deliveryFeeTotal')}
										{loadingTotals ? (
											<OnloadDotted className={classes.loadingTotals} />
										) : (
											<div className={classes.finances_item_wrap}>
												<div className={clsx(classes.finances_item_subtitle, classes.success)}>
													{formatPrice(totals?.main.deliveryFeeTotal, 2, ',')}
												</div>
												{isUA && (
													<DarkTooltip title={t('tooltips.deliveryFeeTotal')} placement="right" color="#fff" arrow>
														<div
															className={clsx(classes.success, classes.totalsAfterPeriodHover, {
																[classes.error]: !isPositive(totals?.period.deliveryFeeTotal),
															})}
														>
															{formatPrice(totals?.period.deliveryFeeTotal, 2, ',', '---', true)}
														</div>
													</DarkTooltip>
												)}
											</div>
										)}
									</div>
									<div className={classes.finances_item_text}>
										{t('totals.deliveryDiscountTotal')}
										{loadingTotals ? (
											<OnloadDotted className={classes.loadingTotals} />
										) : (
											<div className={classes.finances_item_wrap}>
												<div className={clsx(classes.finances_item_subtitle, classes.dark)}>
													{formatPrice(totals?.main.deliveryDiscountTotal, 2, ',')}
												</div>
												{isUA && (
													<DarkTooltip title={t('tooltips.deliveryDiscountTotal')} placement="right" color="#fff" arrow>
														<div className={clsx(classes.dark, classes.totalsAfterPeriodHover)}>
															{formatPrice(totals?.period.deliveryDiscountTotal, 2, ',', '---', true)}
														</div>
													</DarkTooltip>
												)}
											</div>
										)}
									</div>
								</div>
							</div>
						</GetStartedProvider>
					</div>
				)}
			</div>
			<Exports
				rootClassName={clsx(classes.exports, {
					[classes.exportsPartner]: isPartnerWithPromo,
				})}
				buttonClassName={classes.export}
			/>
		</div>
	);
};

export default OrdersTotals;
