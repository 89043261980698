import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { Routes } from 'const';
import { getCountry } from 'helpers/jwt';
import { hasRole } from 'helpers/users';
import { useQuery } from 'hooks/useQuery';
import { useUser } from 'hooks/useUser';
import { RoleEnum } from 'interfaces/users';

import RouteWithLayout from 'components/RouteWithLayout';

import { IProps } from './types';

const ProtectedRoute: React.FC<IProps> = ({ roles, redirectTo = Routes.ORDERS, agreedCountry, query, ...props }) => {
	const { user, loading, roles: userRoles } = useUser();
	const q = useQuery();
	const { pathname } = useLocation();

	if (!user && !loading) return <Redirect to={Routes.LOGIN} />;
	if (userRoles?.length === 1 && hasRole(userRoles, [RoleEnum.CUSTOMER]) && pathname !== Routes.LAAS_ORDERS) {
		return <Redirect to={Routes.LAAS_ORDERS} />;
	}
	if (roles?.length && !hasRole(userRoles, roles) && !loading) return <Redirect to={redirectTo} />;
	if (agreedCountry && getCountry() !== agreedCountry) return <Redirect to={redirectTo} />;
	if (query && !q.has(query)) return <Redirect to={redirectTo} />;

	return <RouteWithLayout {...props} />;
};

export default ProtectedRoute;
