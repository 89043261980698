import { getDate, getMonth, parse, subDays, subMonths } from 'date-fns';
import i18n from 'i18n';
import { AccountingTypes, ERRORS, StatusUnsigned } from 'interfaces';

export const parseDate = (date: string, formatString: string) => parse(date, formatString, new Date());

export const formatDate = (date: Date, separator = '.', showYear = true): string => {
	const day = `${getDate(date) < 10 ? 0 : ''}${getDate(date)}`;
	const month = `${getMonth(date) + 1 < 10 ? 0 : ''}${getMonth(date) + 1}`;
	return `${day}${separator}${month}${showYear ? separator : ''}${showYear ? date.getFullYear() : ''}`;
};

export const formatTime = (date: Date, separator = ':'): string => {
	const hours = `${date.getHours() < 10 ? 0 : ''}${date.getHours()}`;
	const minutes = `${date.getMinutes() < 10 ? 0 : ''}${date.getMinutes()}`;
	return `${hours}${separator}${minutes}`;
};

export const formatTimeLaaS = (dateTime: string) => {
	const [date, time] = dateTime.split('T');
	return `${date ? formatDate(new Date(date)) : ''} ${time ? time.split(':').slice(0, 2).join(':') : ''}`;
};

export const formatPrice = (value: number | undefined, toFixed = 0, separator = ',', catchText = '---', withPlus?: boolean): string => {
	if (!value) return catchText;
	return value
		.toFixed(toFixed)
		.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
		.replace('.', separator)
		.replace('', withPlus ? (value > 0 ? '+' : '') : '');
};

export const roundToPrecision = (value: number, decimals = 2): number => {
	const v = Number(`${value}e${decimals}`);
	return Number(`${Math.round(v)}e-${decimals}`);
};

export const bytesToSize = (bytes: number) => {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	if (bytes === 0) return '0 Byte';
	const i = Number(Math.floor(Math.log(bytes) / Math.log(1024)));
	return roundToPrecision(bytes / Math.pow(1024, i)) + sizes[i];
};

export const getCoords = (elem: HTMLElement | null) => {
	if (!elem) {
		return { top: 0, left: 0, bottom: 0 };
	}
	const box = elem.getBoundingClientRect();
	const body = document.body;
	const docEl = document.documentElement;
	const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
	const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
	const clientTop = docEl.clientTop || body.clientTop || 0;
	const clientLeft = docEl.clientLeft || body.clientLeft || 0;
	const top = box.top + scrollTop - clientTop;
	const left = box.left + scrollLeft - clientLeft;
	return { top, left };
};

export const getScrollTop = () => {
	const body = document.body;
	const docEl = document.documentElement;
	return window.pageYOffset || docEl.scrollTop || body.scrollTop;
};

export const getPublicPath = (path: string) => {
	return `${process.env.PUBLIC_URL}${path.startsWith('/') ? path : `/${path}`}`;
};

export const getAccountingTypes = (accountingTypes: AccountingTypes) => {
	switch (accountingTypes) {
		case AccountingTypes.ONCE_A_WEEK:
			return i18n.t('accountingTypes:options.onceWeek');
		case AccountingTypes.TWICE_A_MONTH:
			return i18n.t('accountingTypes:options.twiceMonth');
		case AccountingTypes.ONCE_A_MONTH:
			return i18n.t('accountingTypes:options.onceMonth');
	}
};

export const getDaysRange = (countDays: number): [Date, Date] => {
	const now = new Date();
	return [subDays(new Date(), countDays), now];
};

export const getMonthRange = (countMonth: number) => {
	const now = new Date();
	return [subMonths(new Date(), countMonth), now];
};

export const copyText = (text: string) => navigator.clipboard.writeText(text);
export const formatNum = (num: number | string, fixed = 0) => (typeof num === 'number' ? num.toFixed(fixed) : num);
export const hasErrorMessage = (res: { message?: string }) => Object.values(ERRORS).find((errName) => res?.message === errName);

export const createLink = (url: string, filename: string) => {
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', filename);
	// Append to html link element page
	document.body.appendChild(link);
	// Start download
	link.click();
	// Clean up and remove the link
	if (link.parentNode) link.parentNode.removeChild(link);
};

export const groupVchasnoStatuses = (filterStatus: string[]) => {
	return filterStatus.reduce((arr: string[], status: string) => {
		const findRelatedStatuses = Object.values(StatusUnsigned).find((statuses) => statuses.includes(status));
		if (findRelatedStatuses) {
			return [...arr, ...findRelatedStatuses.split(',')];
		}
		arr.push(status);
		return arr;
	}, []);
};

export const isPositive = (num: number | undefined) => {
	if (!num) return true;
	return num > 0;
};
