import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as Link } from 'react-router-dom';
import { GLOVO_ROLES, menutoolUrl, PARTNER_ROLES, Routes } from 'const';
import { useAppContext } from 'contexts/App';
import clsx from 'helpers/clsx';
import { getCountry } from 'helpers/jwt';
import { saveTempTokens } from 'helpers/token';
import { hasRole } from 'helpers/users';
import { useUser } from 'hooks/useUser';
import { CountryCodeEnum } from 'interfaces';
import { RoleEnum } from 'interfaces/users';
import { ReactComponent as Accounts } from 'static/images/accounts.svg';
import { ReactComponent as BulkUpdate } from 'static/images/bulkUpdate.svg';
import { ReactComponent as CloseMenu } from 'static/images/close-menu.svg';
import { ReactComponent as Document } from 'static/images/document.svg';
import { ReactComponent as DocumentPlus } from 'static/images/documentPlus.svg';
import { ReactComponent as ImportOrders } from 'static/images/import-orders.svg';
import { ReactComponent as LaasCustomers } from 'static/images/laasCustomers.svg';
import { ReactComponent as LaasOrders } from 'static/images/laasOrders.svg';
import { ReactComponent as Location } from 'static/images/location.svg';
import { ReactComponent as Menu } from 'static/images/Menu.svg';
import { ReactComponent as Menutool } from 'static/images/menutool.svg';
import { ReactComponent as OrdersReport } from 'static/images/order-report.svg';
import { ReactComponent as Orders } from 'static/images/orders.svg';
import { ReactComponent as PartnersFile } from 'static/images/partnersFile.svg';
import { ReactComponent as PaymentShedule } from 'static/images/paymentShedule.svg';
import { ReactComponent as Power } from 'static/images/power.svg';
import { ReactComponent as Stats } from 'static/images/stats.svg';
import { ReactComponent as Users } from 'static/images/users.svg';
import { TEST_IDS } from 'tests/config';
import { groupBy } from 'underscore';

import { DarkTooltip } from 'components/StyledDarkMenuTooltip';

import classes from './MainMenu.module.scss';

const MainMenu: React.FC = () => {
	const { isOpenedMenu, toggleMenu } = useAppContext();
	const { t } = useTranslation(['menu', 'common', 'document']);
	const { roles, loading } = useUser();

	const items = [
		{
			id: 1,
			Icon: Orders,
			text: t('orders'),
			link: Routes.ORDERS,
			render:
				hasRole(
					roles,
					Object.values(RoleEnum).filter((role) => role !== RoleEnum.CUSTOMER),
				) && !loading,
			group: 1,
		},
		{
			id: 2,
			Icon: Location,
			text: t('addresses'),
			link: Routes.STORE_ADDRESSES,
			render: hasRole(roles, [RoleEnum.CREDENTIALS_ADMIN, ...GLOVO_ROLES]) && !loading,
			group: 2,
		},
		{
			id: 3,
			Icon: Users,
			text: t('users'),
			link: Routes.USERS,
			render: hasRole(roles, [RoleEnum.CREDENTIALS_ADMIN, RoleEnum.STORE_ADMIN, RoleEnum.STORE_MANAGER, ...GLOVO_ROLES]) && !loading,
			group: 2,
		},
		{
			id: 4,
			Icon: PaymentShedule,
			text: t('paymentshedule'),
			link: Routes.PAYMENTSCHEDULE,
			render: hasRole(roles, [RoleEnum.CREDENTIALS_ADMIN, ...PARTNER_ROLES, ...GLOVO_ROLES]) && !loading,
			group: 2,
		},
		{
			id: 5,
			Icon: ImportOrders,
			text: t('importOrders'),
			link: Routes.IMPORT_ORDERS,
			render: false,
			group: 4,
		},
		{
			id: 6,
			Icon: Stats,
			text: t('stat'),
			link: Routes.STATISTIC,
			render: false,
			group: 4,
		},
		{
			id: 7,
			Icon: Accounts,
			text: t('accounts'),
			link: Routes.ACCOUNTS,
			render:
				hasRole(
					roles,
					Object.values(RoleEnum).filter((role) => role !== RoleEnum.MENUTOOL && role !== RoleEnum.CUSTOMER),
				) &&
				!loading &&
				getCountry() === CountryCodeEnum.UA,
			group: 1,
		},
		{
			id: 8,
			Icon: PartnersFile,
			text: t('partners'),
			link: Routes.PARTNERS_FILE,
			render: hasRole(roles, [RoleEnum.CREDENTIALS_ADMIN, ...GLOVO_ROLES]) && !loading && getCountry() === CountryCodeEnum.UA,
			group: 3,
		},

		{
			id: 9,
			Icon: OrdersReport,
			text: t('report'),
			link: Routes.ORDER_REPORT,
			render: hasRole(roles, [RoleEnum.ADMIN, RoleEnum.FINANCE]) && !loading,
			group: 1,
		},
		{
			id: 10,
			Icon: Power,
			text: t('powerOutage'),
			link: Routes.POWER_OUTAGE,
			render: hasRole(roles, [RoleEnum.CREDENTIALS_ADMIN, ...PARTNER_ROLES]) && !loading && getCountry() === CountryCodeEnum.UA,
			group: 5,
		},
		{
			id: 11,
			Icon: BulkUpdate,
			text: t('bulkUpdate'),
			link: Routes.BULKUPDATE,
			render: hasRole(roles, [RoleEnum.ADMIN, RoleEnum.FINANCE]) && !loading,
			group: 6,
		},
		{
			id: 12,
			Icon: Document,
			text: t('document:document'),
			link: Routes.DOCUMENTS,
			render:
				hasRole(
					roles,
					Object.values(RoleEnum).filter((role) => role !== RoleEnum.MENUTOOL && role !== RoleEnum.CUSTOMER),
				) &&
				getCountry() === CountryCodeEnum.UA &&
				!loading,
			group: 3,
		},
		{
			id: 13,
			Icon: Menutool,
			text: t('menutool'),
			render: hasRole(roles, [RoleEnum.MENUTOOL]) && !loading,
			onClick(e: React.MouseEvent) {
				e.preventDefault();
				saveTempTokens();
				setTimeout(() => {
					window.location.href = menutoolUrl;
				}, 0);
			},
			group: 4,
		},
		{
			id: 14,
			Icon: DocumentPlus,
			text: t('contractGeneration'),
			link: Routes.CONTRACT_GENERATION,
			render:
				hasRole(roles, [RoleEnum.CREDENTIALS_ADMIN, ...GLOVO_ROLES]) &&
				!loading &&
				(getCountry() === CountryCodeEnum.UA || getCountry() === CountryCodeEnum.KZ),
			group: 3,
		},
		{
			id: 15,
			Icon: LaasOrders,
			text: t('laasOrders'),
			link: Routes.LAAS_ORDERS,
			render: hasRole(roles, [...GLOVO_ROLES, RoleEnum.CREDENTIALS_ADMIN, RoleEnum.CUSTOMER]) && !loading && getCountry() === CountryCodeEnum.UA,
			group: 4,
		},
		{
			id: 16,
			Icon: LaasCustomers,
			text: t('customers'),
			link: Routes.CUSTOMERS,
			render: hasRole(roles, [RoleEnum.CREDENTIALS_ADMIN, ...GLOVO_ROLES]) && !loading && getCountry() === CountryCodeEnum.UA,
			group: 4,
		},
	];

	const IconToggleMenu = isOpenedMenu ? CloseMenu : Menu;

	const groups = groupBy(
		items.filter((it) => !(typeof it.render === 'boolean' && !it.render)),
		'group',
	);

	return (
		<div
			className={clsx(classes.root, {
				[classes.root_opened]: isOpenedMenu,
			})}
		>
			<div className={clsx(classes.openMenu)}>
				<div className={classes.text} onClick={toggleMenu} data-testid={TEST_IDS.MENU_COLLAPSE_TOGGLE_BUTTON}>
					<IconToggleMenu className={classes.icon} />
					{isOpenedMenu ? t('collapse') : null}
				</div>
			</div>
			<nav className={classes.nav}>
				{Object.keys(groups).map((key) => (
					<div key={key} className={classes.group}>
						{groups[key].map((it) => {
							const content = (
								<>
									<div className={classes.icon}>
										<it.Icon />
									</div>
									{isOpenedMenu ? <div>{it.text}</div> : null}
								</>
							);
							if (!it.link) {
								return (
									<div className={classes.text} key={it.id} onClick={it.onClick}>
										{content}
									</div>
								);
							}
							return !isOpenedMenu ? (
								<DarkTooltip arrow title={it.text} placement="right" color="#fff" key={it.id}>
									<Link to={it.link || '/'} activeClassName={classes.active} className={classes.text} onClick={it.onClick}>
										{content}
									</Link>
								</DarkTooltip>
							) : (
								<Link key={it.id} to={it.link || '/'} activeClassName={classes.active} className={classes.text} onClick={it.onClick}>
									{content}
								</Link>
							);
						})}
					</div>
				))}
			</nav>
		</div>
	);
};

export default MainMenu;
