import { gql } from '@apollo/client';

export default gql`
	input OrderFilter {
		startDate: String!
		endDate: String!
		storeAddressId: String
		companyName: String
		storeName: String
		code: String
		orderStatus: [OrderStatus]
		sorting: Sorting
		deliveryDiscountPaidByPartner: Float
		approvedAfterPeriodTab: Boolean
	}

	input AccountingTypeFilter {
		accountingType: Int!
		countryCode: String
	}

	type DatePickerScheduleDto {
		startDate: Date!
		endDate: Date!
	}

	enum OrderStatus {
		PENDING
		AMENDING
		REJECTED
		APPROVED
	}

	input Sorting {
		sortBy: OrderSorting!
		direction: Direction!
	}

	enum OrderSorting {
		ACTIVATION_TIME
		PARTNER_COMMISSION_PERCENTAGE
		CHARGED_TO_PARTNER
		CODE
		STORE_NAME
		COMPANY_NAME
		AMOUNT
	}

	enum Direction {
		ASC
		DESC
	}

	input OrderPartnerAmendmentInputDto {
		orderId: Long!
		amount: BigDecimal
		chargedToPartner: BigDecimal
		paymentMethod: String
		comment: String
		receivedFunds: BigDecimal
		deliveryFee: BigDecimal
		finalStatus: String
		productDiscountAssumedByPartner: BigDecimal
		deliveryDiscountAssumedByPartner: BigDecimal
		deliveryPromotionAssumedByPartner: BigDecimal
		deliveryDiscountSSP: BigDecimal
		deliveryDiscountAssumedByPartner100: BigDecimal
		deliveryDiscountAssumedByPartnerAndGlovo: BigDecimal
	}

	type OrderPartnerAmendmentOutputFileDto {
		fileId: String
		filename: String
		size: Int
	}

	type OrderPartnerAmendmentDto {
		id: String!
		orderId: Long!
		effectiveDate: DateTime!
		createdAt: DateTime!
		createdBy: String!
		amount: BigDecimal!
		chargedToPartner: BigDecimal!
		paymentMethod: String!
		acceptedAt: DateTime!
		acceptedBy: String!
		rejectedAt: DateTime!
		rejectedBy: String!
		comment: String!
		receivedFunds: BigDecimal
		deliveryFee: BigDecimal
		finalStatus: String
		deliveryPromotionAssumedByPartner: BigDecimal
		deliveryDiscountSSP: BigDecimal
		promotionAssumedByPartner: BigDecimal
		deliveryDiscountAssumedByPartner100: BigDecimal
		deliveryDiscountAssumedByPartnerAndGlovo: BigDecimal
		files: [OrderPartnerAmendmentOutputFileDto]
	}

	input StoreAddressFilter {
		searchQuery: String
		temporary: Boolean
	}
	type LastStoreAddressValuesDto {
		id: ID
		storeAddressId: Int
		effectiveDate: Date
		city: String
		storesId: Int
		storeName: String
		address: String
		companyName: String
		taxId: String
		partnerCommissionPercentage: BigDecimal
		accountManagerName: String
		email: String
		email2: String
		createdBy: String
		createdAt: DateTime
		deleted: Boolean
		deletedBy: String
		deletedAt: DateTime
		replacedWithId: String
		temporary: Boolean
		imported: Boolean
		incidentsPaidByGlovo: Boolean
		partnerContractNumber: String
		promoCalculationType: String
		promoProductCalculationType: String
		openDataBotSyncStatus: String
		legalAddress: String
		iban: String
	}

	type StoreAddressFullDto {
		id: ID
		storeAddressId: Int
		effectiveDate: Date
		effectiveUntil: Date
		city: String
		storesId: Int
		storeName: String
		address: String
		companyName: String
		taxId: String
		partnerCommissionPercentage: BigDecimal
		accountManagerName: String
		email: String
		email2: String
		createdBy: String
		createdByEmail: String
		createdAt: DateTime
		deleted: Boolean
		deletedBy: String
		deletedAt: DateTime
		replacedWithId: String
		temporary: Boolean
		imported: Boolean
		accountingType: String
		reason: String
		incidentsPaidByGlovo: Boolean
		partnerContractNumber: String
		promoCalculationType: String
		promoProductCalculationType: String
	}

	type LastStoreAddressValuesDtoPage {
		lastStoreAddressValuesDtos: [LastStoreAddressValuesDto]
		total: Int
		totalPages: Int
	}

	input CommentInputDTO {
		orderId: Long!
		message: String!
	}

	type PartnerCommentResponseDTO {
		id: String!
		message: String!
		createdBy: String!
		createdAt: String!
	}

	input StoreAddressInputDto {
		id: ID
		storeAddressId: Int!
		effectiveDate: Date!
		effectiveUntil: Date
		city: String
		storesId: Int
		storeName: String
		address: String
		companyName: String
		taxId: String
		partnerCommissionPercentage: BigDecimal
		accountManagerName: String
		email: String
		email2: String
		temporary: Boolean
		accountingType: Int
		reason: String
		incidentsPaidByGlovo: Boolean
		partnerContractNumber: String
		category: String
		gen: String
		promoCalculationType: String
		promoProductCalculationType: String
	}

	type PartnerCommentResponseHolderDTO {
		comments: [PartnerCommentResponseDTO!]
		orderId: Long!
	}

	type OrderApprovalShortDto {
		orderId: Long!
		approvedAt: DateTime
		approvedBy: String
		approvedByEmail: String
	}

	type CompanyDto {
		companyName: String!
		taxId: String!
	}

	input StoreAddressDto {
		id: Int!
		storeName: String
		address: String
	}

	type StoreAddressesDataDto {
		id: ID!
		storeAddressId: String
		storeName: String
		address: String
		storeId: Int
		promoCalculationType: String
		promoProductCalculationType: String
	}

	input InvoicePageFilter {
		startDate: String!
		endDate: String!
		edrpou: [String]
		contractNumber: String
		statuses: [String]
	}

	input AccountFilter {
		startDate: Date!
		endDate: Date!
		edrpou: [String]
	}

	input PaymentPageFilter {
		startDate: String!
		endDate: String!
		edrpou: [String]
		contractNumber: String
	}

	type FilterItemDto {
		value: String
		title: String
	}

	input StoreAddressBotFilter {
		searchQuery: String
	}
`;
