import { AccountingTypes, FinalStatus, PaymentMethods } from 'interfaces';

export interface IProductOrder {
	name: string;
	count: number;
	amount: number;
	additionally?: string;
}
export interface IOrder {
	approvedAfterPeriodDto?: {
		approvedAt: string;
		amount: number;
		partnerPayment: number;
		deliveryDiscountAssumedByPartner: number;
		deliveryPromotionAssumedByPartner: number;
		productDiscountAssumedByPartner: number;
		chargedToPartner: number;
		isOriginalPaymentMethod: boolean;
		receivedFunds: number;
		deliveryFee: number;
		promotionAssumedByPartner: number;
		deliveryDiscountPaidByPartner: number;
	};
	id: string;
	code: string;
	activationTime: string;
	storeAddressId: number;
	storeName: string;
	companyName: string;
	amount: number;
	partnerCommissionPercentage: number;
	finalStatus: FinalStatus;
	cancelReason: string;
	partnerResolution: string;
	chargedToPartner: number;
	paymentMethod: PaymentMethods;

	isAction: boolean;
	toBePaid: string;
	returnType: string;
	receivedFunds: number;
	products?: IProductOrder[];
	orders: string[];
	orderStatus: OrderStatus;
	approvedBy: string | null;
	approvedAt: string | null;
	dispatchingTime: string;
	originAmount: number;
	originChargedToPartner: number;
	originPaymentMethod: PaymentMethods;
	promotionAssumedByPartner: number;
	taxId: string;
	isMarketplaceOrder?: boolean;
	deliveryFee?: number;
	partnerPayment?: number;
	deliveryPromotionAssumedByPartner?: number;
	originDeliveryFee?: number;
	originReceivedFunds?: number;
	originFinalStatus?: FinalStatus;
	productDiscountAssumedByPartner: number;
	deliveryDiscountAssumedByPartner: number;
	deliveryDiscountSSP?: number;
	deliveryDiscountAssumedByPartner100?: number;
	deliveryDiscountAssumedByPartnerAndGlovo?: number;
	originDeliveryDiscountAssumedByPartner: number;
	originProductDiscountAssumedByPartner: number;
	originDeliveryPromotionAssumedByPartner?: number;
	originDeliveryDiscountSSP?: number;
	originDeliveryDiscountAssumedByPartner100?: number;
	originDeliveryDiscountAssumedByPartnerAndGlovo?: number;
	promotionDeliveryByPartner: number;
	promoCalculationType: string;
	deliveryDiscountPaidByPartner: number;

	expectedPaymentDate: string | null;
	lastPaymentDate: string | null;
	lastDiffInputDate: string | null;
	startPeriodDate: string | null;
	endPeriodDate: string | null;
	serviceFee?: number;
}

export enum OrderStatus {
	PENDING = 'PENDING',
	AMENDING = 'AMENDING',
	REJECTED = 'REJECTED',
	APPROVED = 'APPROVED',
	AUTO_APPROVAL = 'AUTO_APPROVAL',
}

export enum DeliveryPromo {
	MARKETPLACE_PROMO = 'deliveryPromotionAssumedByPartner',
	PARTNER = 'deliveryDiscountAssumedByPartner',
	SSP = 'deliveryDiscountSSP',
	PARTNER100 = 'deliveryDiscountAssumedByPartner100',
	PARTNER_AND_GLOVO = 'deliveryDiscountAssumedByPartnerAndGlovo',
}

export interface IFilter {
	startDate?: Date;
	endDate?: Date;
	storeName?: string;
	storeAddressId?: string;
	code?: string;
	companyName?: string;
	orderStatus?: OrderStatus[];
	accountingTypes?: number[];
	storeAddress?: string;
	sorting?: ISorting;
	isMarketplaceOrder?: boolean;
	taxId?: string;
	contractNumber?: string;
	approvedAfterPeriodTab?: boolean;
}

export interface ITotals {
	main: {
		chargedToPartnerTotal: number;
		commissionTotal: number;
		orderTotal: number;
		paidCashTotal: number;
		deliveryFeeTotal: number;
		partnerPaymentTotal: number;
		deliveryDiscountTotal: number;
		productDiscountAssumedByPartnerTotal: number;
		productDiscountAssumedByPartnerTotalWithVat: number;
		productDiscountAssumedByPartnerTotalVat: number;
		deliveryDiscountAssumedByPartnerTotal: number;
		deliveryDiscountAssumedByPartnerTotalWithVat: number;
		deliveryDiscountAssumedByPartnerTotalVat: number;
		commissionTotalVat: number;
		commissionTotalWithVat: number;
		additionalCommissionWithoutVat: number;
		productDiscount: number;
		agentFeeCalculationBase: number;
		partnerPaymentWithVat: number;
		partnerPaymentWithoutVat: number;
		partnerPaymentVat: number;
	};
	period: {
		chargedToPartnerTotal: number;
		commissionTotal: number;
		orderTotal: number;
		paidCashTotal: number;
		deliveryFeeTotal: number;
		partnerPaymentTotal: number;
		deliveryDiscountTotal: number;
		productDiscountAssumedByPartnerTotal: number;
		productDiscountAssumedByPartnerTotalWithVat: number;
		productDiscountAssumedByPartnerTotalVat: number;
		deliveryDiscountAssumedByPartnerTotal: number;
		deliveryDiscountAssumedByPartnerTotalWithVat: number;
		deliveryDiscountAssumedByPartnerTotalVat: number;
		commissionTotalVat: number;
		commissionTotalWithVat: number;
		additionalCommissionWithoutVat: number;
		productDiscount: number;
		agentFeeCalculationBase: number;
		partnerPaymentWithVat: number;
		partnerPaymentWithoutVat: number;
		partnerPaymentVat: number;
	};
}

export interface ISorting {
	sortBy: OrderSorting;
	direction: Direction;
}

export enum OrderSorting {
	ACTIVATION_TIME = 'ACTIVATION_TIME',
	PARTNER_COMMISSION_PERCENTAGE = 'PARTNER_COMMISSION_PERCENTAGE',
	CHARGED_TO_PARTNER = 'CHARGED_TO_PARTNER',
	CODE = 'CODE',
	STORE_NAME = 'STORE_NAME',
	COMPANY_NAME = 'COMPANY_NAME',
	AMOUNT = 'AMOUNT',
}

export enum Direction {
	ASC = 'ASC',
	DESC = 'DESC',
}

export interface IFormEdit {
	amount?: number;
	chargedToPartner?: number;
	paymentMethod?: string;
	comment?: string;
	receivedFunds?: number;
	deliveryFee?: number;
	finalStatus?: string;
	productDiscountAssumedByPartner?: number;
	deliveryDiscountAssumedByPartner?: number;
	deliveryPromotionAssumedByPartner?: number;
	deliveryDiscountSSP?: number;
	deliveryDiscountAssumedByPartner100?: number;
	deliveryDiscountAssumedByPartnerAndGlovo?: number;
	promotionAssumedByPartner?: number;
	files?: { fileId: string; filename: string; size: number }[];
}

export interface IAmendment extends IFormEdit {
	id: string;
	createdAt: string;
	createdBy: string;
	acceptedAt: string | null;
	acceptedBy: string | null;
	rejectedAt: string | null;
	rejectedBy: string | null;
	acceptedAfterOrderPeriod?: boolean;
	orderStatus: OrderStatus;
	rejectedReason: string | null;
}

export interface PayoutScheduleDto {
	id: string;
	accountingType: AccountingTypes;
	startDate: string;
	endDate: string;
	expectedPaymentDate: string;
	lastPaymentDate: string;
	lastDiffInputDate: string;
	__typename: string;
}

export enum PromoCalculationType {
	FULL = 'FULL',
	REDUCED = 'REDUCED',
}

export interface IPromoIdResponse {
	id: string;
	storeName: string;
	address: string;
	storeAddressId: number;
	promoCalculationType: string;
}
