import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useQuery, useSubscription } from '@apollo/client';
import { GET_DEBT_INFO } from 'api/invoices';
import { GET_PAYOUT_SCHEDULE_NOTIFICATIONS, PARTNER_COMMENT_NOTIFICATION, SUBSCRIPTION_PAYOUT_SCHEDULE_NOTIFICATION } from 'api/notification';
import { GET_COUNT_VCHASNO_DOCS_UNSIGNED } from 'api/vchasnoDocs';
import { Routes } from 'const';
import format from 'date-fns/format';
import { formatPrice, getMonthRange } from 'helpers';
import clsx from 'helpers/clsx';
import { getCountry } from 'helpers/jwt';
import { filterPayoutScheduleNotifications, getPayoutScheduleNotificationId, isViewedNotification, sendNotification } from 'helpers/notification';
import { hasRole } from 'helpers/users';
import { useDeepCompareEffect } from 'hooks/useDeepCompareEffect';
import { useUser } from 'hooks/useUser';
import { CountryCodeEnum } from 'interfaces';
import { INotificationPayoutSchedule, IPartnerCommentNotification } from 'interfaces/notification';
import { RoleEnum } from 'interfaces/users';
import { ReactComponent as NotificationsIcon } from 'static/images/notifications.svg';
// import { ReactComponent as IconClose } from 'static/images/close.svg';
import { ReactComponent as NotificationsIconIcon } from 'static/images/notifications-active.svg';
import { TEST_IDS } from 'tests/config';

import IconButton from 'components/IconButton';
import IconInsideCircle from 'components/IconInsideCircle';
import { TypeIcon } from 'components/IconInsideCircle/types';
import Tooltip from 'components/Tooltip';

import classes from './Notifications.module.scss';

interface IDebtUser {
	getDeptForUser: {
		expiredDebtAmount: number;
		expiredDebt30DaysAmount: number;
		totalDebtAmount: number;
	};
}

const Notifications: React.FC = () => {
	const [notificationPayoutItems, setNotificationPayoutItems] = useState<INotificationPayoutSchedule[]>([]);
	const [forceOpenTooltip, setForceOpenTooltip] = useState<boolean>(false);
	const { roles, user, loading } = useUser();
	const isCustomer = roles?.length === 1 && hasRole(roles, [RoleEnum.CUSTOMER]);
	const { data: payoutScheduleNotifications, loading: loadingNotifications } = useQuery<{
		payoutScheduleNotifications: INotificationPayoutSchedule[];
	}>(GET_PAYOUT_SCHEDULE_NOTIFICATIONS, {
		skip: loading || isCustomer,
	});
	const { data: payoutScheduleNotification } = useSubscription<{ payoutScheduleNotification: INotificationPayoutSchedule }>(
		SUBSCRIPTION_PAYOUT_SCHEDULE_NOTIFICATION,
		{
			skip: loading || isCustomer,
		},
	);
	const { data: partnerCommentNotification } = useSubscription<{ partnerCommentNotification: IPartnerCommentNotification }>(
		PARTNER_COMMENT_NOTIFICATION,
		{ variables: { email: user?.email }, skip: !user?.email || isCustomer },
	);
	const { data: dataUnsigned } = useQuery<{ vchasnoDocsUnsignedCount: number }>(GET_COUNT_VCHASNO_DOCS_UNSIGNED, {
		variables: (() => {
			const [startDate] = getMonthRange(12);
			return { dateFrom: format(startDate, 'yyyy-MM-dd') };
		})(),
		skip:
			getCountry() !== CountryCodeEnum.UA ||
			!hasRole(
				roles,
				Object.values(RoleEnum).filter((role) => role !== RoleEnum.MENUTOOL && role !== RoleEnum.CUSTOMER),
			),
	});
	const { data: dataDebt } = useQuery<IDebtUser>(GET_DEBT_INFO, {
		skip: getCountry() !== CountryCodeEnum.UA || loading || isCustomer,
	});
	const { t } = useTranslation('notifications');
	// const handleClickCloseNotification = (notification: INotificationPayoutSchedule) => () => {
	// 	const notificationId = getPayoutScheduleNotificationId(notification);
	// 	saveViewedPayoutScheduleNotification(notification);
	// 	setNotificationPayoutItems((state) => state.filter((n) => {
	// 		const selfId = getPayoutScheduleNotificationId(n);
	// 		return notificationId !== selfId;
	// 	}));
	// };

	useDeepCompareEffect(() => {
		if (payoutScheduleNotification && !loadingNotifications) {
			const { payoutScheduleNotification: notification } = payoutScheduleNotification;
			const notificationId = getPayoutScheduleNotificationId(notification);
			const isViewed = isViewedNotification(notification);
			const alredyExist = notificationPayoutItems.find((it) => {
				const id = getPayoutScheduleNotificationId(it);
				return notificationId === id;
			});
			if (!isViewed && !alredyExist) {
				setNotificationPayoutItems((state) => [...state, notification]);
				setForceOpenTooltip(true);
				sendNotification(t('newMessage'));
			}
		}
	}, [notificationPayoutItems, loadingNotifications, payoutScheduleNotification]);

	useEffect(() => {
		if (payoutScheduleNotifications?.payoutScheduleNotifications) {
			const filteredItems = filterPayoutScheduleNotifications(payoutScheduleNotifications?.payoutScheduleNotifications);
			setNotificationPayoutItems(filteredItems);
			if (filteredItems.length) {
				setForceOpenTooltip(true);
				sendNotification(t(filteredItems.length === 1 ? 'newMessage' : 'newMessages'));
			}
		}
	}, [payoutScheduleNotifications?.payoutScheduleNotifications?.length]);

	useEffect(() => {
		if (partnerCommentNotification?.partnerCommentNotification) {
			sendNotification(t('newMessage'));
		}
	}, [partnerCommentNotification]);

	useDeepCompareEffect(() => {
		if (dataUnsigned?.vchasnoDocsUnsignedCount) {
			sendNotification(t('unsignedDocs.text', { count: dataUnsigned?.vchasnoDocsUnsignedCount }));
		}
		if (dataDebt?.getDeptForUser?.expiredDebtAmount) {
			sendNotification(t('debt.alert', { debt: dataDebt?.getDeptForUser?.expiredDebtAmount }));
		}
	}, [dataUnsigned?.vchasnoDocsUnsignedCount, dataDebt?.getDeptForUser]);

	const notificationCount = useMemo(() => {
		let count = notificationPayoutItems.length;
		if (dataUnsigned?.vchasnoDocsUnsignedCount) count++;
		if (dataDebt?.getDeptForUser?.expiredDebtAmount) count++;
		if (partnerCommentNotification?.partnerCommentNotification) count++;

		return count;
	}, [
		dataUnsigned?.vchasnoDocsUnsignedCount,
		notificationPayoutItems.length,
		dataDebt?.getDeptForUser,
		partnerCommentNotification?.partnerCommentNotification,
	]);

	if (loadingNotifications) return null;

	return (
		<Tooltip
			withClick
			outside
			forceOpen={forceOpenTooltip}
			placement="bottom"
			className={clsx(classes.root, { [classes.comment_root]: partnerCommentNotification?.partnerCommentNotification })}
			text={
				!!notificationCount && (
					<>
						<div className={classes.head}>
							{t('title')}
							<span className={classes.head__count}>{notificationCount}</span>
						</div>
						<div className={classes.items}>
							{!!partnerCommentNotification?.partnerCommentNotification && (
								<div className={classes.notification}>
									<IconInsideCircle type={TypeIcon.COMMENT} className={classes.notificationIcon} />
									<div className={classes.notificationWrap}>
										{`${t('commentedIntoDoc.text')} ${partnerCommentNotification.partnerCommentNotification.contractNumber}:`}
										<br />
										<div
											className={classes.notificationComment}
											dangerouslySetInnerHTML={{ __html: partnerCommentNotification.partnerCommentNotification.comment }}
										/>
										<div className={classes.notificationLink}>
											<Link
												to={`${Routes.PARTNERS_FILE}?search=${partnerCommentNotification.partnerCommentNotification.contractNumber}`}
												data-testid={TEST_IDS.NOTIFICATIONS_LINK}
											>
												{t('commentedIntoDoc.link')}
											</Link>
										</div>
									</div>
								</div>
							)}
							{!!dataUnsigned?.vchasnoDocsUnsignedCount && (
								<div className={classes.notification}>
									<IconInsideCircle type={TypeIcon.DOCS} className={classes.accountingType} />
									<div>
										{t('unsignedDocs.text', { count: dataUnsigned?.vchasnoDocsUnsignedCount })}
										<div className={classes.notificationLink}>
											<Link to={`${Routes.DOCUMENTS}?showUnsigned=true`} data-testid={TEST_IDS.NOTIFICATIONS_LINK}>
												{t('unsignedDocs.link')}
											</Link>
										</div>
									</div>
								</div>
							)}
							{!!dataDebt?.getDeptForUser?.expiredDebtAmount && (
								<>
									<div className={clsx(classes.notification, { [classes.withDebt]: !!dataDebt?.getDeptForUser.expiredDebt30DaysAmount })}>
										<IconInsideCircle type={TypeIcon.DEBT} className={classes.debtIcon} />
										<div className={classes.debtList}>
											<div className={classes.debtRow} data-testid={TEST_IDS.NOTIFICATIONS_DEBT_TOTAL_TEXT}>
												<div>{t('debt.totalText')}</div>
												<div>{formatPrice(dataDebt?.getDeptForUser?.totalDebtAmount, 2, ',', '0,00')}</div>
											</div>
											<div className={classes.debtRow} data-testid={TEST_IDS.NOTIFICATIONS_DEBT_EXPIRED_TEXT}>
												<div>{t('debt.expiredText')}</div>
												<div>{formatPrice(dataDebt?.getDeptForUser?.expiredDebtAmount, 2, ',', '0,00')}</div>
											</div>
											<div className={classes.notificationLink}>
												<Link to={Routes.ACCOUNTS} data-testid={TEST_IDS.NOTIFICATIONS_LINK}>
													{t('debt.link')}
												</Link>
											</div>
										</div>
									</div>
									{!!dataDebt?.getDeptForUser.expiredDebt30DaysAmount && (
										<div className={classes.expiredDebt30}>
											<Trans t={t} i18nKey="debt.warning" components={{ bold: <span className={classes.bold} /> }} />
										</div>
									)}
								</>
							)}
							{notificationPayoutItems.map((it) => (
								<div className={classes.notification} key={it.accountingType + it.periodStart + it.periodEnd}>
									<IconInsideCircle type={it.accountingType} className={classes.accountingType} />
									<div>
										<Trans
											t={t}
											i18nKey="payoutSchedule"
											values={{
												range: `${format(new Date(it.periodStart), 'dd.MM')}-${format(new Date(it.periodEnd), 'dd.MM')}`,
												deadLine: format(new Date(it.reconciliationDeadline), 'dd.MM'),
												days: it.daysLeft,
											}}
											components={{
												warn: <span className={classes.warn} />,
												nowrap: <span className={classes.nowrap} />,
											}}
										/>
									</div>
									{/* TODO: will be needed in the future */}
									{/* <IconClose
										onClick={handleClickCloseNotification(it)}
										className={classes.iconClose}
									/> */}
								</div>
							))}
						</div>
					</>
				)
			}
		>
			<IconButton className={classes.notifications} testId={TEST_IDS.NOTIFICATIONS_BUTTON}>
				{notificationCount ? (
					<NotificationsIconIcon className={clsx(classes.icon, classes.icon__active)} />
				) : (
					<NotificationsIcon className={classes.icon} />
				)}
				{!!notificationCount && (
					<span className={classes.notifications__badge} data-testid={TEST_IDS.NOTIFICATIONS_COUNT}>
						{notificationCount}
					</span>
				)}
			</IconButton>
		</Tooltip>
	);
};

export default Notifications;
