import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyQueryHookOptions, useLazyQuery, useQuery } from '@apollo/client';
import { clientGraphql } from 'api';
import { GET_CUSTOMERS } from 'api/customers';
import { GET_STORE_ADDRESSES_DATA, GET_STORE_ADRESSES } from 'api/storeAddresses';
import { GET_CUSTOMERS_DATA } from 'api/users';
import { GLOVO_ROLES, glovoDomainRegex } from 'const';
import clsx from 'helpers/clsx';
import { STORE_TYPE } from 'helpers/storeAddresses';
import { getCustomerIds, getRoles, getUserStoresIds, hasRole, parseStoreIds } from 'helpers/users';
import { isValidEmail } from 'helpers/validation';
import useToast from 'hooks/useToast';
import { useUser } from 'hooks/useUser';
import { useUsers } from 'hooks/useUsers';
import { ICustomer } from 'interfaces/customer';
import { RoleEnum } from 'interfaces/users';
import { ReactComponent as SearchIcon } from 'static/images/search.svg';
import { TEST_IDS } from 'tests/config';

import { Input } from 'components/FormControl';
import Modal from 'components/modals/Modal';
import Roles from 'components/Roles';
import UserEmail from 'components/UserEmail';

import ListItem from './ListItem';
import { IListItem, IPropsTemplate } from './types';

import classes from './CreateEditUser.module.scss';

const CreateEditUser: React.FC<IPropsTemplate> = ({ user, onClose }) => {
	const { roles: currentUserRoles } = useUser();
	const userRoles = getRoles(user);
	const userAddress = getUserStoresIds(user);
	const customerIds = (user && getCustomerIds(user)) || [];
	const [roles, setRoles] = useState<RoleEnum[]>(userRoles || []);
	const [email, setEmail] = useState<string>('');
	const [query, setQuery] = useState<string>('');
	const [queryCustomer, setQueryCustomer] = useState<string>('');
	const [scrollOpacity, setScrollOpacity] = useState<boolean>(false);
	const [scrollOpacityCustomers, setScrollOpacityCustomers] = useState<boolean>(false);
	const [selectedAddresses, setSelectedAddresses] = useState<IListItem[]>([]);
	const [selectedCustomers, setSelectedCustomers] = useState<ICustomer[]>([]);
	const [fetchedAddresses, setFetchedAddresses] = useState<IListItem[]>([]);
	const [fetchedCustomers, setFetchedCustomers] = useState<ICustomer[]>([]);
	const [isFocused, setFocused] = useState(false);
	const [isFocusedCustomer, setFocusedCustomer] = useState(false);
	const [showStoreAddressesList, setShowStoreAddressesList] = useState(true);
	const [fetchAddresses, { loading: loadingAddresses, data: storesResponse }] = useLazyQuery(GET_STORE_ADRESSES);
	const [fetchCustomers, { loading: loadingCustomers, data: customersResponse }] = useLazyQuery(GET_CUSTOMERS);
	const { loading: loadingStoresData, data: storesData } = useQuery(GET_STORE_ADDRESSES_DATA, {
		variables: { storeAddressIds: userAddress },
	});
	const { loading: loadingCustomersData, data: customersData } = useQuery(GET_CUSTOMERS_DATA, {
		variables: { customerIds },
	});

	const params: LazyQueryHookOptions = {
		variables: { page: 0, pageSize: 100, filter: { searchQuery: query, status: [STORE_TYPE.APPROVED, STORE_TYPE.TEMPORARY, STORE_TYPE.NEW] } },
		fetchPolicy: 'network-only',
	};

	const paramsCustomer: LazyQueryHookOptions = {
		variables: { page: 0, pageSize: 1000000, search: queryCustomer },
		fetchPolicy: 'network-only',
	};

	const { createUser, updateUserRoles, fetchUsers } = useUsers();
	const { toast } = useToast();
	const [createLoad, setCreateLoad] = useState<boolean>(false);
	const { t } = useTranslation(['createUser', 'roles', 'validation', 'errors']);
	const handleChangeRole = (newRole: RoleEnum) => {
		if (roles.includes(newRole)) {
			setRoles((state) => state.filter((role) => role !== newRole));
		} else {
			setRoles((state) => [...state, newRole]);
		}
	};

	const handleChangeEmail = (value: string) => {
		setEmail(value);
	};

	const handleChangeInput = (value: string) => {
		setQuery(value);
	};

	const handleChangeInputCustomer = (value: string) => {
		setQueryCustomer(value);
	};

	const handleSelectAdresses = (storeValue: IListItem) => () => {
		setFetchedAddresses(fetchedAddresses.filter((store) => store.id !== storeValue.id));
		setSelectedAddresses([...selectedAddresses, storeValue]);
	};

	const handleSelectCustomers = (customer: ICustomer) => () => {
		setFetchedCustomers(fetchedCustomers.filter((c) => c.id !== customer.id));
		setSelectedCustomers([...selectedCustomers, customer]);
	};

	const handleRemoveAddress = (id: string) => () => {
		const elem = selectedAddresses.find((store) => store.id === id);
		if (elem) setFetchedAddresses([...fetchedAddresses, elem]);
		const filtred = selectedAddresses.filter((store) => store.id !== id);
		setSelectedAddresses(filtred);
	};

	const handleRemoveCustomer = (customerId: number) => () => {
		const elem = selectedCustomers.find((c) => c.customerId === customerId);
		if (elem) setFetchedCustomers([...fetchedCustomers, elem]);
		const filtred = selectedCustomers.filter((c) => c.customerId !== customerId);
		setSelectedCustomers(filtred);
	};

	const handleFocusSearch = () => {
		setFocused(true);
	};

	const handleFocusSearchCustomer = () => {
		setFocusedCustomer(true);
	};

	const handleBlurSearch = () => {
		setFocused(false);
	};

	const handleBlurSearchCustomer = () => {
		setFocusedCustomer(false);
	};

	useEffect(() => {
		if (selectedAddresses.length >= 4) return setScrollOpacity(true);
		setScrollOpacity(false);
	}, [selectedAddresses]);

	useEffect(() => {
		if (selectedCustomers.length >= 4) return setScrollOpacityCustomers(true);
		setScrollOpacityCustomers(false);
	}, [selectedCustomers]);

	useEffect(() => {
		if (query.length <= 3) return setFetchedAddresses([]);
		fetchAddresses(params);
	}, [query]);

	useEffect(() => {
		if (queryCustomer.length <= 3) return setFetchedCustomers([]);
		fetchCustomers(paramsCustomer);
	}, [queryCustomer]);

	useEffect(() => {
		if (storesResponse) {
			const {
				storeAddresses: { lastStoreAddressValuesDtos: stores },
			} = storesResponse;
			if (!loadingAddresses) {
				setFetchedAddresses(stores.filter((storeValue: IListItem) => !selectedAddresses.find((el) => el.id === storeValue.id)));
			}
		}
	}, [loadingAddresses]);

	useEffect(() => {
		if (customersResponse) {
			const {
				customers: { data: customersInfo },
			} = customersResponse;
			if (!loadingCustomers) {
				setFetchedCustomers(customersInfo.filter((customer: ICustomer) => !selectedCustomers.find((el) => el.id === customer.id)));
			}
		}
	}, [loadingCustomers]);

	useEffect(() => {
		if (!user) return;
		if (!loadingStoresData && storesData?.storeAddressData.length) setSelectedAddresses(storesData.storeAddressData);
		if (!loadingCustomersData && customersData?.customersData.length) setSelectedCustomers(customersData.customersData);
		return () => {
			setSelectedAddresses([]);
			setSelectedCustomers([]);
		};
	}, [loadingStoresData, loadingCustomersData]);

	const isGlovoRoles = hasRole(roles, GLOVO_ROLES);
	const canAddCustomerRole = hasRole(currentUserRoles, [...GLOVO_ROLES, RoleEnum.CREDENTIALS_ADMIN]);
	const hasPartnerRole = hasRole(roles, [RoleEnum.STORE_MANAGER, RoleEnum.MENUTOOL, RoleEnum.ACCOUNTANT]);
	const hasCustomerRole = hasRole(roles, [RoleEnum.CUSTOMER]);
	const canHasGlovoRoles = (isValidEmail(email) && !!email?.match(glovoDomainRegex)) || !!user?.email.match(glovoDomainRegex);

	useEffect(() => {
		if (!canHasGlovoRoles && isGlovoRoles) {
			setRoles(roles.filter((role) => !GLOVO_ROLES.includes(role)));
		}
	}, [canHasGlovoRoles]);

	const handleCreateUser = () => {
		if (!isValidEmail(email) && !user) {
			return toast(t('validation:errors.email'), { type: 'error' });
		}
		if (!roles.length) {
			return toast(t('validation:errors.rolesEmpty'), { type: 'error' });
		}
		if (hasPartnerRole && !selectedAddresses.length && !isGlovoRoles) {
			return toast(t('validation:errors.addressEmpty'), { type: 'error' });
		}
		if (hasCustomerRole && !selectedCustomers.length && !isGlovoRoles) {
			return toast(t('validation:errors.customersEmpty'), { type: 'error' });
		}
		const storesIds = parseStoreIds(selectedAddresses);
		const storeAddresses = selectedAddresses.map(({ id, __typename, ...store }) => store);
		const customersIds = selectedCustomers.map((customer) => customer.customerId);
		if (user) {
			return updateUserRoles(user, roles, storesIds, storeAddresses, customersIds)
				.then(() => {
					toast(t('editRoles.success'), { type: 'success' });
					onClose();
				})
				.catch(() => {
					toast(t('errors:somethingWentWrong'), { type: 'error' });
				});
		}
		setCreateLoad(true);
		createUser(roles, email, storesIds, storeAddresses, customersIds)
			.then(async () => {
				toast(t('successCreate'), { type: 'success' });
				onClose();
				await clientGraphql.clearStore();
				fetchUsers();
			})
			.catch((res) => {
				if (res && res.message.includes('already exist')) {
					return toast(t('errors:userAlreadyExist'), { type: 'error' });
				}
				toast(t('errors:createUser'), { type: 'error' });
			})
			.finally(() => {
				setCreateLoad(false);
			});
	};

	const handleActive = (param: boolean) => () => setShowStoreAddressesList(param);

	return (
		<>
			<Modal
				modalClassName={classes.modal}
				bodyClassName={classes.body}
				title={user ? t('editRoles.title') : t('title')}
				alignTitle="center"
				withoutShadowTitle
				open
				onClose={onClose}
				buttons={{
					cancel: {},
					confirm: {
						text: user ? t('editRoles.ok') : t('add'),
						onClick: handleCreateUser,
						className: createLoad ? classes.btnDisabled : '',
					},
				}}
			>
				{user ? (
					<UserEmail isOnline rootClassName={classes.userEmail}>
						{user.email}
					</UserEmail>
				) : (
					<Input value={email} onChange={handleChangeEmail} placeholder="name@example.com" label={t('labelEmail')} />
				)}
				<div className={classes.rolesLabel}>{t('rolesLabel')}</div>
				<Roles
					checkboxClassName={classes.badge}
					roles={roles}
					onChangeRole={handleChangeRole}
					className={clsx(classes.justifyContentBetween, classes.badges)}
					canHasGlovoRoles={canHasGlovoRoles}
				/>
				{hasPartnerRole && hasCustomerRole && canAddCustomerRole && (
					<div className={classes.tabs}>
						<button onClick={handleActive(true)} className={clsx({ [classes.active]: showStoreAddressesList })}>
							{t('facilityAddressTab')}
						</button>
						<button onClick={handleActive(false)} className={clsx({ [classes.active]: !showStoreAddressesList })}>
							{t('facilityCustomerTab')}
						</button>
					</div>
				)}
				{(canAddCustomerRole ? hasPartnerRole && !hasCustomerRole : hasPartnerRole) && (
					<div className={classes.rolesLabel}>{t('facilityAddress')}</div>
				)}
				{hasCustomerRole && !hasPartnerRole && canAddCustomerRole && <div className={classes.rolesLabel}>{t('facilityCustomer')}</div>}
				{(hasCustomerRole ? hasPartnerRole && showStoreAddressesList : hasPartnerRole) && (
					<div>
						<Input
							clear
							Icon={SearchIcon}
							onChange={handleChangeInput}
							placeholder={t('createUser:selectAddresses')}
							rootClassName={classes.searchItem}
							value={query}
							delayOnChange={500}
							onFocus={handleFocusSearch}
							onBlur={handleBlurSearch}
						/>
						{isFocused && !!fetchedAddresses.length && (
							<div className={classes.searchList}>
								{fetchedAddresses?.map(({ id, storeAddressId, storeName, address }) => (
									<ListItem
										key={id}
										storeAddressId={storeAddressId}
										storeName={storeName}
										address={address}
										onSelect={handleSelectAdresses({ id, storeAddressId, storeName, address })}
									/>
								))}
							</div>
						)}
						<div className={clsx(classes.selectedList, { [classes.selectedList_scroll]: scrollOpacity })} data-testid={TEST_IDS.SELECTED_LIST}>
							{selectedAddresses?.map(({ id, storeAddressId, storeName, address }) => (
								<ListItem key={id} storeAddressId={storeAddressId} storeName={storeName} address={address} onRemove={handleRemoveAddress(id)} />
							))}
						</div>
					</div>
				)}
				{(hasPartnerRole ? hasCustomerRole && !showStoreAddressesList : hasCustomerRole) && canAddCustomerRole && (
					<div>
						<Input
							clear
							Icon={SearchIcon}
							onChange={handleChangeInputCustomer}
							placeholder={t('createUser:selectCustomer')}
							rootClassName={classes.searchItem}
							value={queryCustomer}
							delayOnChange={500}
							onFocus={handleFocusSearchCustomer}
							onBlur={handleBlurSearchCustomer}
						/>
						{isFocusedCustomer && !!fetchedCustomers.length && (
							<div className={classes.searchList}>
								{fetchedCustomers?.map((customer) => (
									<ListItem
										key={customer.customerId}
										storeAddressId={customer.customerId}
										storeName={customer.name}
										address={customer.address}
										onSelect={handleSelectCustomers(customer)}
									/>
								))}
							</div>
						)}
						<div
							className={clsx(classes.selectedList, { [classes.selectedList_scroll]: scrollOpacityCustomers })}
							data-testid={TEST_IDS.SELECTED_LIST}
						>
							{selectedCustomers?.map((customer) => (
								<ListItem
									key={customer.customerId}
									storeAddressId={customer.customerId}
									storeName={customer.name}
									address={customer.address}
									onRemove={handleRemoveCustomer(customer.customerId)}
								/>
							))}
						</div>
					</div>
				)}
			</Modal>
		</>
	);
};

export default CreateEditUser;
