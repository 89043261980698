import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ApolloProvider } from '@apollo/client';
import { clientGraphql } from 'api';
import { GLOVO_ROLES, PARTNER_ROLES, Routes } from 'const';
import Maintenance from 'containers/Maintenance';
import AnnouncementPopup from 'containers/modals/ReleaseAnnouncement';
import ProtectedRoute from 'containers/ProtectedRoute';
import AppContextProvider from 'contexts/App';
import GetStartedContextProvider from 'contexts/GetStarted';
import { CountryCodeEnum } from 'interfaces';
import { RoleEnum } from 'interfaces/users';
import LoginLayout from 'layouts/login';
import Accounts from 'pages/Accounts';
import BulkUpdate from 'pages/BulkUpdate';
import ContractForm from 'pages/ContractForm';
import ContractGeneration from 'pages/ContractGeneration';
import Customers from 'pages/Customers';
import DocumentPreview from 'pages/DocumentPreview';
import Documents from 'pages/Documents';
import Faq from 'pages/Faq';
import ForgotPassword from 'pages/ForgotPassword';
import Index from 'pages/Index';
import LaaSOrders from 'pages/LaaSOrders';
import Login from 'pages/Login';
import NotFound from 'pages/NotFound';
import Orders from 'pages/Orders';
import PartnersFile from 'pages/PartnersFile';
import PartnersFilePermissions from 'pages/PartnersFilePermissions';
import PaymentSchedule from 'pages/PaymentSchedule';
import PowerOutage from 'pages/PowerOutage';
import Questionaire from 'pages/Questionnaire';
import Report from 'pages/Report';
import StoreAddresses from 'pages/StoreAddresses';
import Users from 'pages/Users';

import Loader from 'components/Loader';
import RouteWithLayout from 'components/RouteWithLayout';

import 'helpers/rum';
import 'i18n';

import store from './store';

import 'react-toastify/dist/ReactToastify.css';
import 'static/styles/index.scss';

const App: React.FC = () => {
	const { t } = useTranslation('meta');
	return (
		<>
			<Helmet>
				<title>{t('title')}</title>
				<meta name="description" content={t('description')} />
			</Helmet>
			<Switch>
				<ProtectedRoute path="/" exact component={Index} />
				<ProtectedRoute path={Routes.ORDERS} exact component={Orders} roles={Object.values(RoleEnum).filter((role) => role !== RoleEnum.CUSTOMER)} />
				<ProtectedRoute path={Routes.ORDER_BY_CODE} exact component={Orders} />
				<ProtectedRoute
					exact
					agreedCountry={CountryCodeEnum.UA}
					roles={[...GLOVO_ROLES, RoleEnum.CREDENTIALS_ADMIN, RoleEnum.CUSTOMER]}
					path={Routes.LAAS_ORDERS}
					component={LaaSOrders}
				/>
				<ProtectedRoute
					exact
					roles={[RoleEnum.CREDENTIALS_ADMIN, ...PARTNER_ROLES, ...GLOVO_ROLES]}
					path={Routes.PAYMENTSCHEDULE}
					component={PaymentSchedule}
				/>
				<ProtectedRoute
					path={Routes.USERS}
					exact
					component={Users}
					roles={[RoleEnum.CREDENTIALS_ADMIN, RoleEnum.STORE_ADMIN, RoleEnum.STORE_MANAGER, ...GLOVO_ROLES]}
				/>
				<ProtectedRoute path={Routes.ORDER_REPORT} exact component={Report} roles={[RoleEnum.ADMIN, RoleEnum.FINANCE]} />
				<ProtectedRoute
					path={Routes.DOCUMENTS}
					agreedCountry={CountryCodeEnum.UA}
					exact
					component={Documents}
					roles={Object.values(RoleEnum).filter((role) => role !== RoleEnum.MENUTOOL && role !== RoleEnum.CUSTOMER)}
				/>
				<ProtectedRoute
					exact
					path={Routes.ACCOUNTS}
					agreedCountry={CountryCodeEnum.UA}
					component={Accounts}
					roles={Object.values(RoleEnum).filter((role) => role !== RoleEnum.MENUTOOL && role !== RoleEnum.CUSTOMER)}
				/>
				<ProtectedRoute path={Routes.BULKUPDATE} exact component={BulkUpdate} roles={[RoleEnum.ADMIN, RoleEnum.FINANCE]} />
				<ProtectedRoute path={Routes.LOGIN} exact component={Login} layout={LoginLayout} />
				<RouteWithLayout path={Routes.FORGOT_PASSWORD} exact component={ForgotPassword} layout={LoginLayout} />
				<ProtectedRoute path={Routes.STORE_ADDRESSES} exact component={StoreAddresses} roles={[RoleEnum.CREDENTIALS_ADMIN, ...GLOVO_ROLES]} />
				<ProtectedRoute path={Routes.POWER_OUTAGE} exact component={PowerOutage} roles={[RoleEnum.CREDENTIALS_ADMIN, ...PARTNER_ROLES]} />
				<ProtectedRoute
					exact
					path={Routes.PARTNERS_FILE}
					agreedCountry={CountryCodeEnum.UA}
					component={PartnersFile}
					roles={[RoleEnum.CREDENTIALS_ADMIN, ...GLOVO_ROLES]}
				/>
				<ProtectedRoute path={Routes.CONTRACT_GENERATION} exact component={ContractGeneration} roles={[RoleEnum.CREDENTIALS_ADMIN, ...GLOVO_ROLES]} />
				<ProtectedRoute exact path={Routes.QUESTIONNAIRE} component={Questionaire} roles={[RoleEnum.CREDENTIALS_ADMIN, ...GLOVO_ROLES]} />
				<ProtectedRoute exact path={Routes.CONTRACT_FORM} component={ContractForm} roles={[RoleEnum.CREDENTIALS_ADMIN, ...GLOVO_ROLES]} />
				<ProtectedRoute exact path={Routes.DOCUMENT_PREVIEW} component={DocumentPreview} roles={[RoleEnum.CREDENTIALS_ADMIN, ...GLOVO_ROLES]} />
				<ProtectedRoute
					exact
					path={Routes.PARTNERS_FILE_PERMISSIONS}
					agreedCountry={CountryCodeEnum.UA}
					component={PartnersFilePermissions}
					roles={[RoleEnum.CREDENTIALS_ADMIN]}
				/>
				<ProtectedRoute
					exact
					agreedCountry={CountryCodeEnum.UA}
					component={Customers}
					path={Routes.CUSTOMERS}
					roles={[...GLOVO_ROLES, RoleEnum.CREDENTIALS_ADMIN]}
				/>
				<ProtectedRoute exact path={Routes.FAQ} component={Faq} />
				<RouteWithLayout component={NotFound} />
			</Switch>
		</>
	);
};

ReactDOM.render(
	<ApolloProvider client={clientGraphql}>
		<ReduxProvider store={store}>
			<Suspense fallback={<Loader />}>
				<ToastContainer hideProgressBar autoClose={3000} limit={3} />
				<BrowserRouter basename={process.env.PUBLIC_URL}>
					<AppContextProvider>
						<GetStartedContextProvider>
							<AnnouncementPopup />
							<Maintenance>
								<App />
							</Maintenance>
						</GetStartedContextProvider>
					</AppContextProvider>
				</BrowserRouter>
			</Suspense>
		</ReduxProvider>
	</ApolloProvider>,
	document.getElementById('root'),
);
