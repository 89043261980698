import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'helpers/clsx';
import { useOrders } from 'hooks/useOrders';
import { useUser } from 'hooks/useUser';
import { Direction, OrderSorting } from 'interfaces/order';
import { ReactComponent as Arr } from 'static/images/keyboard_arrow_down.svg';
import { TEST_IDS } from 'tests/config';

import { IFild } from './types';

import classes from './OrdersContainer.module.scss';

const OrdersTableHead: React.FC = () => {
	const { hasMarketplace } = useUser();
	const { changeSorting, items, filter } = useOrders();
	const { t } = useTranslation('orders');

	const fields: IFild[] = [
		{
			id: 'code',
			title: t('fields.code'),
			render: true,
			className: classes.idCell,
			sorting: OrderSorting.CODE,
		},
		{
			id: 'approveDate',
			title: t('fields.approveDate'),
			render: !!filter.approvedAfterPeriodTab,
			className: classes.dateCell,
		},
		{
			id: 'date',
			title: t('fields.date'),
			render: true,
			className: classes.dateCell,
			sorting: OrderSorting.ACTIVATION_TIME,
		},
		{
			id: 'storeAddressId',
			title: t('fields.storeAddressId'),
			render: true,
			className: classes.pointCodeCell,
		},
		{
			id: 'storeName',
			title: t('fields.storeName'),
			render: true,
			sorting: OrderSorting.STORE_NAME,
		},
		{
			id: 'companyName',
			title: t('fields.companyName'),
			render: true,
			sorting: OrderSorting.COMPANY_NAME,
		},
		{
			id: 'amount',
			title: t('fields.amount'),
			render: true,
			className: classes.alignRight,
			sorting: OrderSorting.AMOUNT,
		},
		{
			id: 'productDiscountAssumedByPartner',
			title: t('fields.productDiscountAssumedByPartner'),
			className: clsx(classes.alignRight, classes.nowrap),
			render: true,
		},
		{
			id: 'deliveryDiscountAssumedByPartner',
			title: t('fields.deliveryDiscountAssumedByPartner'),
			className: clsx(classes.alignRight, classes.nowrap),
			render: true,
		},
		// {
		// 	id: 'isAction',
		// 	title: t('fields.isAction'),
		// 	render: true,
		// 	className: classes.isActionCell,
		// },
		{
			id: 'promotionAssumedByPartner',
			title: t('fields.promotionAssumedByPartner'),
			render: true,
			className: clsx(classes.alignCenter, classes.nowrap),
		},
		{
			id: 'promotionDeliveryByPartner',
			title: t('fields.promotionDeliveryByPartner'),
			render: true,
			className: clsx(classes.alignCenter, classes.nowrap),
		},
		{
			id: 'partnerCommissionPercentage',
			title: t('fields.partnerCommissionPercentage'),
			render: true,
			className: clsx(classes.alignRight, classes.percentCell),
			sorting: OrderSorting.PARTNER_COMMISSION_PERCENTAGE,
		},
		{
			id: 'receivedFunds',
			title: t('fields.receivedFunds'),
			render: hasMarketplace,
		},
		{
			id: 'deliveryFee',
			title: t('fields.deliveryFee'),
			render: hasMarketplace,
		},
		{
			id: 'deliveryPromotionAssumedByPartner',
			title: t('fields.deliveryPromotionAssumedByPartner'),
			render: hasMarketplace,
		},
		{
			id: 'serviceFee',
			title: t('fields.serviceFee'),
			render: hasMarketplace,
		},
		{
			id: 'partnerPayment',
			title: t('fields.partnerPayment'),
			render: true,
		},
		{
			id: 'toBePaid',
			title: t('fields.toBePaid'),
			render: true,
		},
		{
			id: 'finalStatus',
			title: t('fields.finalStatus'),
			render: true,
		},
		{
			id: 'cancelReason',
			title: t('fields.cancelReason'),
			render: true,
		},
		{
			id: 'partnerResolution',
			title: t('fields.partnerResolution'),
			render: true,
		},
		{
			id: 'chargedToPartner',
			title: t('fields.chargedToPartner'),
			render: true,
			className: classes.alignRight,
			sorting: OrderSorting.CHARGED_TO_PARTNER,
		},
		// {
		// 	id: 'returnType',
		// 	title: t('fields.returnType'),
		// 	render: true,
		// },
		{
			id: 'paymentMethod',
			title: t('fields.paymentMethod'),
			render: true,
		},
	];

	const handleChangeSorting = (sortBy: OrderSorting) => () => changeSorting(sortBy);

	return (
		<thead data-testid={TEST_IDS.ORDERS_TABLE_HEAD}>
			<tr className={classes.sticky}>
				{fields.map((it) => {
					if (!it.render) return null;
					const activeSort = filter.sorting?.sortBy && filter.sorting.sortBy === it.sorting;
					const canSort = it.sorting && items.length;
					const reverseSort = activeSort && filter.sorting?.direction === Direction.DESC;
					return (
						<td
							key={it.id}
							className={clsx(it.className, {
								[classes.canSort]: canSort,
								[classes.activeSort]: activeSort,
								[classes.activeSort_reverse]: activeSort && filter.sorting?.direction === Direction.DESC,
							})}
							onClick={it.sorting && items.length ? handleChangeSorting(it.sorting) : undefined}
							data-testid={clsx({
								[TEST_IDS.ORDERS_TABLE_HEAD_SORT_CELL]: canSort,
								[TEST_IDS.ORDERS_TABLE_HEAD_ACTIVE_SORT_CELL]: activeSort,
								[TEST_IDS.ORDERS_TABLE_HEAD_ACTIVE_SORT_REVERSE_CELL]: reverseSort,
							})}
						>
							<div data-testid={it?.id}>
								{it.title.split('\n').map((h) => (
									<div key={h}>{h}</div>
								))}
								{activeSort && <Arr />}
							</div>
						</td>
					);
				})}
			</tr>
		</thead>
	);
};

export default OrdersTableHead;
