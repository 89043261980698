import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetStartedContext } from 'contexts/GetStarted';
import { formatPrice } from 'helpers';
import clsx from 'helpers/clsx';
import { useClickAwayListener } from 'hooks/useClickAwayListener';
import { useExport } from 'hooks/useExport';
import { useLaaSOrders } from 'hooks/useLaaSOrders';
import { useUser } from 'hooks/useUser';
import { Entries } from 'interfaces';
import { ReactComponent as Arr } from 'static/images/keyboard_arrow_down.svg';
import { ReactComponent as TotalIcon } from 'static/images/total.svg';
import { TEST_IDS } from 'tests/config';

import ExportButton from 'components/ExportButton';
import OnloadDotted from 'components/OnloadDotted';

import classes from './Orders.module.scss';

interface IStateOpened {
	baseAgentTotal: boolean;
	agentTotalWithVat: boolean;
	partnerTotal: boolean;
	promoProducts: boolean;
	promoDelivery: boolean;
	deliveryFee: boolean;
}

const OrdersTotals: React.FC = () => {
	const { open } = useGetStartedContext();
	const { exportLaaSOrders, exportLaaSSummary } = useExport();
	const { isServiceUser, canSeeLaasPartnerExport, isAdmin, isFinance, isAccount } = useUser();
	const { totals, loadingTotals, items } = useLaaSOrders();
	const { t } = useTranslation(['orders', 'exports', 'common']);
	const refTotals = useRef(null);
	const [opened, setOpened] = useState<IStateOpened>({
		baseAgentTotal: false,
		agentTotalWithVat: false,
		partnerTotal: false,
		promoProducts: false,
		promoDelivery: false,
		deliveryFee: false,
	});

	const clearOpened = () => {
		setOpened((state) => ({
			...Object.fromEntries(Object.entries(state).map(([key]: Entries<IStateOpened>) => [key, false])),
		}));
	};

	const handleChangeOpened = (field: keyof IStateOpened) => () => {
		setOpened((state) => ({
			...Object.fromEntries(
				Object.entries(state)
					.filter(([key]) => key !== field)
					.map(([key]: Entries<IStateOpened>) => [key, false]),
			),
			[field]: !state[field],
		}));
	};

	const getStartedOpened = () => !open && clearOpened();

	useClickAwayListener(refTotals, getStartedOpened, open);

	useEffect(() => {
		if (open) {
			handleChangeOpened('baseAgentTotal')();
		} else {
			clearOpened();
		}
	}, [open]);

	return (
		<div className={classes.totals}>
			<div className={classes.finances} ref={refTotals}>
				<div
					className={clsx(classes.finances_item, classes.finances_item_total, {
						[classes.finances_item__opened]: opened.baseAgentTotal,
					})}
				>
					<div>
						<div className={clsx(classes.finances_item_top, classes.alignCenter)}>
							<TotalIcon />
							<div>
								<div className={classes.finances_item_text}>{t('totals.paidByPartner')}</div>
								{loadingTotals ? (
									<OnloadDotted className={classes.loadingTotals}>{t('common:loading')}</OnloadDotted>
								) : (
									<div className={classes.finances_item_title}>
										{formatPrice(totals && totals?.paidByPartner, 2, ',')}
										<button
											className={classes.finances_item_toggle}
											onClick={handleChangeOpened('baseAgentTotal')}
											data-testid={TEST_IDS.ORDERS_TOTALS_BUTTON_BASE_AGENT}
										>
											{t('common:detail')}
											<Arr />
										</button>
									</div>
								)}
							</div>
						</div>
						<div className={clsx(classes.finances_item_bottom, classes.spaceBetween)}>
							<div className={classes.finances_item_text}>
								{t('totals.glovoFee')}
								{loadingTotals ? (
									<OnloadDotted className={classes.loadingTotals} />
								) : (
									<div className={clsx(classes.finances_item_subtitle, classes.success)}>{formatPrice(totals?.glovoFee, 2, ',')}</div>
								)}
							</div>
							<div className={classes.finances_item_text}>
								{t('totals.vatLaas')}
								{loadingTotals ? (
									<OnloadDotted className={classes.loadingTotals} />
								) : (
									<div className={clsx(classes.finances_item_subtitle, classes.success)}>{formatPrice(totals?.vat, 2, ',')}</div>
								)}
							</div>
							<div className={classes.finances_item_text}>
								{t('totals.incident')}
								{loadingTotals ? (
									<OnloadDotted className={classes.loadingTotals} />
								) : (
									<div className={clsx(classes.finances_item_subtitle, classes.error)}>{formatPrice(totals?.incident, 2, ',')}</div>
								)}
							</div>
						</div>
					</div>
				</div>
				{items?.length > 0 && (
					<div className={classes.container}>
						{isServiceUser && (
							<ExportButton className={classes.exportButton} onClick={() => exportLaaSOrders()}>
								{t('exports:laas')}
							</ExportButton>
						)}
						{canSeeLaasPartnerExport && (
							<ExportButton className={classes.exportButton} onClick={() => exportLaaSOrders(true)}>
								{t('exports:laas-partner')}
							</ExportButton>
						)}
						{(isAdmin || isFinance || isAccount || isServiceUser) && (
							<ExportButton onClick={exportLaaSSummary} className={classes.exportButton}>
								{t('exports:summary')}
							</ExportButton>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default OrdersTotals;
