import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { addYears } from 'date-fns';
import en from 'date-fns/locale/en-US';
import ru from 'date-fns/locale/ru';
import uk from 'date-fns/locale/uk';
import clsx from 'helpers/clsx';
import { ReactComponent as Calendar } from 'static/images/calendar.svg';
import { TEST_IDS } from 'tests/config';

import { IPropsDatepicker } from './types';

import 'react-datepicker/dist/react-datepicker.css';
import classes from './FormControl.module.scss';
registerLocale('uk', uk);
registerLocale('en-US', en);
registerLocale('ru', ru);

const DatePickerInput: React.ForwardRefRenderFunction<HTMLDivElement, IPropsDatepicker> = (
	{
		rootClassName,
		controlClassName,
		onChange,
		disabled = false,
		positionIcon = 'left',
		placeholder,
		label,
		datepickerProps,
		forceOpenDatepicker,
		testId = TEST_IDS.DATEPICKER,
		showMonthYearPicker = false,
		format = 'dd.MM.yyyy',
		calendarClassName = '',
		withPortal = false,
		subLabel,
		isClearable = false,
	},
	refRoot,
) => {
	const { twoMonth = false, minDate, maxDate, startDate: startDateProp, endDate: endDateProp } = datepickerProps || {};
	const [startDate, setStartDate] = useState(startDateProp);
	const [endDate, setEndDate] = useState(endDateProp);
	const [isOpenDatepicker, setIsOpenDatepicker] = useState(false);
	const { i18n } = useTranslation();

	useEffect(() => {
		setStartDate(startDateProp);
	}, [startDateProp]);

	useEffect(() => {
		setEndDate(endDateProp);
	}, [endDateProp]);

	const openDatepicker = () => {
		setIsOpenDatepicker(true);
	};

	const closeDatepicker = () => {
		setIsOpenDatepicker(false);
	};

	const handleChangeDatePickerRange = (dates: [Date, Date]) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);
		if (end) {
			closeDatepicker();
		}
		onChange?.(dates);
	};

	const handleChangeDatePicker = (date: Date) => {
		setStartDate(date);
		closeDatepicker();
		onChange?.(date);
	};

	return (
		<div
			ref={refRoot}
			className={clsx(classes.root, classes.input, rootClassName, {
				[classes.positionIconRight]: positionIcon === 'right',
			})}
		>
			{label && (
				<div className={classes.label}>
					{label}
					{subLabel && (
						<React.Fragment>
							<br />
							<span className={classes.subLabel}>{subLabel}</span>
						</React.Fragment>
					)}
				</div>
			)}
			<div className={classes.inner}>
				<Calendar className={classes.icon} />
				<DatePicker
					showMonthDropdown
					showYearDropdown
					focusSelectedMonth
					isClearable={isClearable}
					placeholderText={placeholder}
					open={isOpenDatepicker || forceOpenDatepicker}
					onInputClick={openDatepicker}
					selected={startDate}
					disabled={disabled}
					onChange={twoMonth ? handleChangeDatePickerRange : handleChangeDatePicker}
					customInput={
						<input
							readOnly
							className={clsx(classes.control, controlClassName, classes.withDatePicker, classes.withIcon, {
								[classes.focus]: isOpenDatepicker,
							})}
							type="text"
							data-testid={testId}
						/>
					}
					selectsRange={twoMonth}
					endDate={endDate}
					startDate={startDate}
					minDate={minDate || new Date('2018-01-01')}
					maxDate={maxDate || addYears(new Date(), 1)}
					monthsShown={twoMonth ? 2 : 1}
					onClickOutside={closeDatepicker}
					locale={i18n.language}
					dateFormat={format}
					showMonthYearPicker={showMonthYearPicker}
					calendarClassName={clsx(classes.datepicker, { [classes.simple]: !twoMonth }, calendarClassName)}
					dayClassName={() => classes.datepickerCell}
					withPortal={withPortal}
				/>
			</div>
		</div>
	);
};

export default React.forwardRef(DatePickerInput);
